import {
    GET_USER_CLIENTS_LIST_REQUEST,
    GET_USER_CLIENTS_LIST_SUCCESS,
    SELECT_ACTIVE_RELOAD,
    SELECT_ACTIVE_CLIENT,
} from '../actions/types';

const initialState = {
    clientsList: [],
    loading: false,
    isClientChanging: false,
    activeClient: '',
};

const headerReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_USER_CLIENTS_LIST_REQUEST:
            return { ...state, loading: true };
        case GET_USER_CLIENTS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                clientsList: action.payload.data.client_access,
            };
        case SELECT_ACTIVE_RELOAD:
            return { ...state, isClientChanging: true };
        case SELECT_ACTIVE_CLIENT:
            return {
                ...state,
                activeClient: action.payload,
                isClientChanging: false,
            };
        default:
            return state;
    }
};

export default headerReducer;
