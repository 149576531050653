import React, { useState, useEffect, memo } from 'react';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import { PageLabel } from './PageLabel';

export interface IPaginate {
    total: number;
    handlePageChange: any;
    loading: boolean;
}
export interface IPageSize {
    value: number;
    label: string;
}
export const pageSizes: IPageSize[] = [
    { value: 10, label: '10' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
];

export const receiveTransactionAlertPageSizes: IPageSize[] = [
    { value: 10, label: '10' }
];

export const getPageCount = (total: number, perPage: number): number =>
    Math.ceil(total / perPage);
export const getPageStart = (currentPage: number, perPage: number): number =>
    (currentPage - 1) * perPage;
export const getPageEnd = (
    currentPage: number,
    perPage: number,
    total: number
): number => {
    let end = getPageStart(currentPage + 1, perPage);
    return total > end ? end : total;
};

export const Paginate = memo((props: IPaginate) => {
    const { total, handlePageChange, loading = false } = props;
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState<IPageSize>({
        value: 10,
        label: '10',
    });
    /** number is 0 indexed here */
    const onPageChange = ({ selected }: { selected: number }) => {
        setCurrentPage(selected + 1);
    };
    useEffect(() => {
        handlePageChange(
            getPageStart(currentPage, perPage.value),
            perPage.value
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, perPage, handlePageChange]);
    return total && !loading ? (
        <div className="payin-pagination-container vertical-center flex-1">
            <div className="vertical-center pagination-select">
                <PageLabel
                    total={total}
                    perPage={perPage.value}
                    currentPage={currentPage}
                />
                <Select
                    id="paginationSize"
                    name="perPage"
                    className="basic-select"
                    classNamePrefix="select"
                    defaultValue={perPage}
                    options={pageSizes}
                    styles={{
                        container: (provided) => ({
                            ...provided,
                            width: '80px',
                        }),
                    }}
                    value={perPage}
                    onChange={(value: any) => setPerPage(value)}
                    menuPortalTarget={document.querySelector('body')}
                />
            </div>
            <div className="v-separator ml-3 mr-3" />
            <ReactPaginate
                activeClassName="payin-active"
                breakLabel={'...'}
                breakClassName="payin-break-me"
                containerClassName="payin-pagination"
                forcePage={currentPage - 1}
                marginPagesDisplayed={1}
                nextLabel="Next"
                onPageChange={onPageChange}
                pageRangeDisplayed={5}
                pageCount={getPageCount(total, perPage.value)}
                previousLabel="Previous"
            />
        </div>
    ) : null;
});
