export const payoutMethod = {
    CASH_PAYOUT: 'Cash',
};

export const segmentList = [
    { value: 'FI', label: 'FI' },
    { value: 'NonFI', label: 'NonFI' },
    { value: 'SME', label: 'SME' },
];

export const AlertStatuses = {
    CLOSED: 'CLOSED',
    IN_PROGRESS: 'IN_PROGRESS',
    OPEN: 'OPEN',
};

export const payoutMethodOpt = [
    { value: 'CASH_PAYOUT', label: 'CASH' },
    { value: 'BANK', label: 'BANK' },
    { value: 'WALLET', label: 'WALLET' },
    { value: 'CARD', label: 'CARD' },
];

export const statusList = [
    { value: 'PENDING', label: 'Pending' },
    { value: 'APPROVED', label: 'Approved' },
    { value: 'STAGE_1_APPROVED', label: 'Stage 1 Approved' },
    { value: 'STAGE_2_APPROVED', label: 'Stage 2 Approved' },
    {
        value: 'PROCESSING_TRANSACTION',
        label: 'Processing Transaction',
    },
    { value: 'MANUAL_PG', label: 'Manual PG' },
    { value: 'SENT_TO_BANK', label: 'Sent to Bank' },
    { value: 'USER_REJECTED', label: 'User Rejected' },
    { value: 'REJECTED', label: 'Rejected' },
    { value: 'ERROR', label: 'Error' },
    { value: 'AWAITING_FUNDS', label: 'Awaiting Funds' },
    { value: 'CANCELLED', label: 'Cancelled' },
];

export const paidReturnedStatuses = [
    { value: 'PAID', label: 'Paid' },
    { value: 'RETURNED', label: 'Returned' },
]

export const iccStatus = [
    { value: 'ERROR', label: 'Error' },
    { value: 'APPROVED', label: 'Approved' }
]

export const riskTypesArray = ['All', 'High', 'Medium', 'Low'];

export const defaultNonBPSPPricing = {
    Aust: 1.2,
    HK: 2.5,
    Singapore: 2.5,
    Malaysia: 1.5,
};

export const defaultStatusFilters = {
    alert: ['OPEN', 'IN_PROGRESS'],
    rfi: ['RECEIVED', 'PENDING'],
    paymentAlertStatus: ['OPEN'],
    paymentAlertSubStaus: ['NEW'],
    paymentAlertTransactionStatus: ['ERROR']
};

export const RESTRICT_DECIMAL_CURRENCIES = [ 'IDR', 'JPY', 'VND' ];

export const formulaInjectionCharacters = ['=', '+', '-', '@'];

export const ComplianceHistoryStatus = [
    { value: 'COMPLIANCE_APPROVED', label: 'COMPLIANCE APPROVED' },
    { value: 'COMPLIANCE_REJECTED', label: 'COMPLIANCE REJECTED' },
    { value: 'COMPLIANCE_AUTO_APPROVED', label: 'COMPLIANCE AUTO APPROVED' },
]

export const ComplianceVerificationStatus = [
    { value: 'COMPLIANCE_SCREENING', label: 'COMPLIANCE SCREENING' },
    { value: 'RFI_RAISED', label: 'RFI RAISED' },
    { value: 'RFI_RESPONDED', label: 'RFI RESPONDED' },
    { value: 'IN_PROCESS', label: 'IN PROCESS' },
    { value: 'COMPLIANCE_ERROR', label: 'COMPLIANCE ERROR' },
]

export const ComplianceAlertStatus = [
    { value: 'OPEN', label: 'OPEN' },
    { value: 'IN_PROGRESS', label: 'IN PROGRESS' },
    { value: 'CLOSED', label: 'CLOSED' },
]

export const ComplianceAlertCaasStatus = [
    { value: 'ALL', label: 'ALL' },
    { value: 'OPEN', label: 'OPEN' },
]

export const RiskLevels = [
    { value: 'HIGH', label: 'HIGH' },
    { value: 'MEDIUM', label: 'MEDIUM' },
    { value: 'LOW', label: 'LOW' },
];

export const AutosweepLegalEntities = [
    'Japan',
    'Malaysia'
]

export const AutosweepLegalEntityMapping = {
    MYR: 'Malaysia',
    JP: 'Japan'
}

export const ScheduledBookFxStatus = [
    { value: 'OPEN', label: 'OPEN' },
    { value: 'FUNDED', label: 'FUNDED' },
    { value: 'EXPIRED', label: 'EXPIRED' },
    { value: 'CANCELLED', label: 'CANCELLED' }
];

export const EntityTypes = {
    BENEFICIARY: 'Beneficiary',
    REMITTER: 'Remitter',
    CLIENT: 'Client'
}

export const ComplianceListType = {
    Blacklist: 'BLACK LIST',
    Watchlist: 'WATCH LIST',
    Whitelist: 'WHITE LIST',
    None: 'NONE',
}

export const ClientComplianceListTypes = [
    ComplianceListType.Blacklist,
    ComplianceListType.Watchlist,
    ComplianceListType.None
]

export const defaultStuckTransactionsFilters = {
    OPEN: ['OPEN'],
    REJECTED: ['REJECTED']
};

export const rejectedIccStatusOpt = [
    { value: 'REJECTED', label: 'REJECTED' },
    { value: 'OPEN', label: 'OPEN' },
    { value: 'APPROVED', label: 'APPROVED' }
];

export const iccComplianceStatus = {
    OPEN: 'OPEN',
    REJECTED: 'REJECTED'
};

export const iccType = {
    VFA: 'VFA',
    BANK: 'BANK',
    FPX: 'FPX'
}

export const Receive_Transaction_List = {
    IN_HOLD: 'IN_HOLD',
    IN_ALERT: 'IN_ALERT',
};

export const InstitutionTypes = {
    FI: 'FI',
    NonFI: 'NonFI',
    SME: 'SME'
};

export const AutoSweepLegalEntitiesSME = ['Malaysia', 'HK', 'Indonesia']

export const LicenseTypes = {
    TYPE_1: 'TYPE_1',
    TYPE_2: 'TYPE_2'
};

export const acquisitionTypes = {
    BANK: 'BANK',
    NON_BANK: 'NON_BANK'
}

export const autoSweepTopUpCurrencies =  ['Singapore', 'Aust', 'HK', 'Malaysia'];