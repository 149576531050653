import {
    MANUAL_PAYOUT_CONFIRMATION_DATA_REQUEST,
    MANUAL_PAYOUT_CONFIRMATION_DATA_SUCCESS,
    MANUAL_PAYOUT_CONFIRMATION_DATA_FAILURE,
    MANUAL_PAYOUT_CONFIRMATION_BANKS_REQUEST,
    MANUAL_PAYOUT_CONFIRMATION_ISO_RETURN_REQUEST,
    REFETCH_DATA_REQUEST,
    CHANGE_PAGE_SIZE_REQUEST,
    MANUAL_PAYOUT_CONFIRMATION_FILTER_CHANGE_TYPE,
    MANUAL_PAYOUT_CONFIRMATION_FILTER_CHANGE_STATUS,
    MANUAL_PAYOUT_CONFIRMATION_SUBMIT_STATUS_SUCCESS,
} from '../../actions/types';

const initialState = {
    pagination: { items: [] },
    loading: false,
    refetchData: false,
    bankList: [],
    isoList: [],
    type: { value: 'CONFIRMATION', label: 'Confirmation' },
    status: { value: 'SENT_TO_BANK', label: 'Sent to bank' },
    error: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case MANUAL_PAYOUT_CONFIRMATION_DATA_REQUEST:
            return { ...state, loading: true, refetchData: false, error: null };
        case MANUAL_PAYOUT_CONFIRMATION_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                pagination: {
                    items: action.payload.data.data,
                    total: action.payload.data.recordsFiltered,
                },
            };
        case MANUAL_PAYOUT_CONFIRMATION_DATA_FAILURE:
            return { ...state, loading: false };
        case MANUAL_PAYOUT_CONFIRMATION_BANKS_REQUEST:
            return { ...state, bankList: action.payload.data, error: null };
        case MANUAL_PAYOUT_CONFIRMATION_ISO_RETURN_REQUEST:
            return { ...state, isoList: action.payload };
        case REFETCH_DATA_REQUEST:
            return { ...state, loading: true, refetchData: true, error: null };
        case CHANGE_PAGE_SIZE_REQUEST:
            return { ...state, loading: true, refetchData: true };
        case MANUAL_PAYOUT_CONFIRMATION_FILTER_CHANGE_TYPE:
            return { ...state, type: action.payload };
        case MANUAL_PAYOUT_CONFIRMATION_FILTER_CHANGE_STATUS:
            return { ...state, status: action.payload };
        case MANUAL_PAYOUT_CONFIRMATION_SUBMIT_STATUS_SUCCESS:
            return { ...state, loading: true, refetchData: true };
        default:
            return state;
    }
}
