import moment, { utc } from 'moment-timezone';
/**
 * '2020-06-24T04:59:41.869Z' ==> 24/06/2020 10:29
 */
export const ymdFormat = 'YYYY-MM-DD';
export const getLocaleDate = (timeStamp: string) => {
    if (!timeStamp) return '';
    return moment(timeStamp).format('DD/MM/YYYY HH:mm');
};

export const getLocaleDateWithFormat = (timeStamp: string, format: string) => {
    if (!timeStamp) return '';
    return moment(timeStamp).format(format || 'DD/MM/YYYY HH:mm');
};

export const getUtcFormattedDateTime = (timeStamp: string): string => {
    if (!timeStamp) return '';
    return utc(timeStamp).format('DD/MM/YYYY HH:mm');
};
