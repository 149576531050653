import { GL_ENTRY_BATCH_ACTION } from "../../actions/types";

const batchGlEntryReducer = (
    state = {
        batchLedgerHistory: { items: [] },
        result: {},
        error: {},
        errorTable: {},
        currentStep: 1,
        lastStep: 0,
        loading: false,
        refetchData: false,
        loadingDataTable: false,
        uploading: false,
        processedPercent: 10,
        fileName: ''
    },
    action = {}
) => {
    switch (action.type) {
        case GL_ENTRY_BATCH_ACTION: 
            return { ...state, ...action.payload }
        default:
            return state;
    }
};

export default batchGlEntryReducer;
