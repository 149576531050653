export interface IRfiDocumentType {
    id: string;
    type: string;
    code: string;
    name: string;
    rfiType: rfiTypeEnum;
}

export enum rfiTypeEnum {
    COMPLIANCE = 'COMPLIANCE',
    FINOPS = 'FINOPS',
}
export interface IDocumentDetail {
    id: string;
    filePath: string;
    generatedFileName: string;
    originalName: string;
    comment: string;
    createdBy: string;
    updatedBy: string;
    status: string;
    isBackDocument: boolean;
    isActive: boolean;
    mimeType: string;
    size: number;
    createdAt: string;
    updatedAt: string;
    documentTypeId: string;
}
