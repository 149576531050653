import {
    COMPLIANCE_MANAGEMENT_DATA_REQUEST,
    COMPLIANCE_MANAGEMENT_DATA_SUCCESS,
    COMPLIANCE_MANAGEMENT_DATA_FAILURE,
    COMPLIANCE_MANAGEMENT_CONFIGURATION,
    COMPLIANCE_MANAGEMENT_CONFIGURATION_FAILURE,
    COMPLIANCE_MANAGEMENT_HISTORY_REQUEST,
    COMPLIANCE_MANAGEMENT_HISTORY_SUCCESS,
    COMPLIANCE_MANAGEMENT_HISTORY_FAILURE,
    CHANGE_PAGE_SIZE_REQUEST,
    REFETCH_DATA,
    COMPLIANCE_MANAGEMENT_UPDATE_FAILURE,
} from '../../actions/types';

const initialState = {
    pagination: [],
    refetchData: true,
    loading: false,
    historyLoading: false,
    error: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case COMPLIANCE_MANAGEMENT_DATA_REQUEST:
            return { ...state, loading: true, refetchData: false, error: null };
        case COMPLIANCE_MANAGEMENT_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                pagination: {
                    items: action.payload.data.response,
                    total: action.payload.data.recordsTotal,
                },
            };
        case COMPLIANCE_MANAGEMENT_DATA_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case COMPLIANCE_MANAGEMENT_CONFIGURATION:
            return { ...state, configuration: action.payload.data };
        case COMPLIANCE_MANAGEMENT_CONFIGURATION_FAILURE:
            return { error: action.payload };
        case COMPLIANCE_MANAGEMENT_HISTORY_REQUEST:
            return { ...state, historyLoading: true, error: null };
        case COMPLIANCE_MANAGEMENT_HISTORY_SUCCESS:
            return {
                ...state,
                historyLoading: false,
                historyPagination: action.payload.data,
            };
        case COMPLIANCE_MANAGEMENT_HISTORY_FAILURE:
            return { ...state, historyLoading: false, error: action.payload };
        case CHANGE_PAGE_SIZE_REQUEST:
            return { ...state, loading: true, refetchData: true, error: null };
        case REFETCH_DATA:
            return { ...state, refetchData: true };
        case COMPLIANCE_MANAGEMENT_UPDATE_FAILURE:
            return { ...state, loading: false, error: action.payload.message };
        default:
            return state;
    }
}
