import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { thunkApiType } from '../../../../Interface';
import {
    ITransactionRequestBody,
} from './getTransactions';
import { selectFilter } from './reconcileSelector';
import { resetFilterChanged } from './reconcileSlice';
import { ymdFormat } from '../../../utils/dateFormat';
import { Transaction } from '../../../constants/Transaction';

export const downloadTransactions = createAsyncThunk(
    'downloadTransactions',
    async (
        body: ITransactionRequestBody,
        { getState, dispatch }: thunkApiType
    ) => {
        const reduxState: any = getState();
        const { filterClient, filterCurrency, filterDate, filterChanged,  filterStatus, filterTransactionRefNo, filterCountry, filterPayerName, filterActionedBy, filterSegment, filterRiskType, filterClientLegalEntity, filterVirtualAccountNumber } =
            selectFilter(reduxState);

        if (filterChanged) {
            dispatch(resetFilterChanged);
        }

        let { start, limit, status, statusDescription } = body;

        const response = await axios.get('/transactionStatementReport/downloadTransactionHistory', {
            params: {
                start,
                limit,
                clientId: filterClient?._id,
                currency: filterCurrency?.value || null,
                startDate: filterDate?.startDate?.format(ymdFormat),
                endDate: filterDate?.endDate?.format(ymdFormat),
                status: isEmpty(filterStatus) ? status : filterStatus.value ,
                transactionReferenceNumber: filterTransactionRefNo || null,
                payerName: filterPayerName || null,
                country: !filterCountry.length ? null : filterCountry,
                clientSegment: !filterSegment.length ? null : filterSegment,
                updatedBy: !filterActionedBy.length ? null : filterActionedBy,
                riskLevel: filterRiskType.value,
                clientLegalEntity: !filterClientLegalEntity.length ? null : filterClientLegalEntity,
                virtualAccountNumber: !filterVirtualAccountNumber.length ? null: filterVirtualAccountNumber,
                statusDescription,
            },
        });

        const blob = new Blob([response.data], {
            type: 'application/x-www-form-urlencoded; charset=UTF-8',
        });
        const reportName =
            status === Transaction.pageStatus.COMPLIANCE_VERIFICATION
                ? 'Transaction_Reports_InProcess.csv'
                : 'Transaction_Reports.csv';
        saveAs(blob, reportName);
    }
);
