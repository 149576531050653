import {
    COMPLIANCE_ALERT_DATA_REQUEST,
    COMPLIANCE_ALERT_DATA_SUCCESS,
    COMPLIANCE_ALERT_DATA_FAILURE,
    CHANGE_PAGE_SIZE_REQUEST,
    COMPLIANCE_ALERT_GET_ALERTDOCSDETAILS_REQUEST,
    COMPLIANCE_ALERT_GET_ALERTDOCSDETAILS_SUCCESS,
    COMPLIANCE_ALERT_UPDATED_BY_LIST,
    COMPLIANCE_ALERT_CHECKBOX,
    COMPLIANCE_ALERT_APPROVE_TRANSACTION,
    COMPLIANCE_ALERT_APPROVE_TRANSACTION_SUCCESS,
    COMPLIANCE_ALERT_CHANGE_STATUS_REQUEST,
    COMPLIANCE_ALERT_CHANGE_STATUS_SUCCESS,
    COMPLIANCE_ALERT_RESPONSE_APPROVAL_FAILURE,
    COMPLIANCE_SCAN_CSV_DOWNLOAD_REQUEST,
    COMPLIANCE_SCAN_CSV_DOWNLOAD_SUCCESS,
    ERROR,
    RECEIVE_COMPLIANCE_ALERT_CHANGE_STATUS_REQUEST,
    RECEIVE_COMPLIANCE_ALERT_CHANGE_STATUS_SUCCESS
} from '../../actions/types';

const initialState = {
    clientListAll: [],
    pagination: { items: [] },
    caasData: {
        items: [],
        total: 0
    },
    loading: false,
    isWaitingApprovalOrRejection: false,
    approved: '',
    pendingItems: [],
    approvedItems: [],
    failedItems: [],
    loadingDataTable: '',
    reqCurrentInfo: [],
    ALERTCheckbox: '',
    alertApprovedByList: [],
    error: null,
};
export default function (state = initialState, action = {}) {
    switch (action.type) {
        case COMPLIANCE_ALERT_DATA_REQUEST:
            return { ...state, loading: true, error: null };
        case COMPLIANCE_ALERT_DATA_SUCCESS:
            return action.isCaas ? {
                ...state,
                loading: false,
                caasData: {
                    items: action.payload.data.data,
                    total: action.payload.data.recordsFiltered,
                },
            } : {
                ...state,
                loading: false,
                pagination: {
                    items: action.payload.data.data,
                    total: action.payload.data.recordsFiltered,
                },
            };
        case COMPLIANCE_ALERT_DATA_FAILURE:
            return { ...state, loading: false };
        case CHANGE_PAGE_SIZE_REQUEST:
            return { ...state, loading: true };
        case COMPLIANCE_ALERT_GET_ALERTDOCSDETAILS_REQUEST:
            return { ...state, loadingDataTable: true, error: null };
        case COMPLIANCE_ALERT_GET_ALERTDOCSDETAILS_SUCCESS:
            return {
                ...state,
                loadingDataTable: false,
                reqCurrentInfo: action.payload,
            };
        case COMPLIANCE_ALERT_UPDATED_BY_LIST:
            return { ...state, alertApprovedByList: action.payload };
        case COMPLIANCE_ALERT_CHECKBOX:
            return { ...state, ALERTCheckbox: action.payload };
        case COMPLIANCE_ALERT_APPROVE_TRANSACTION:
            return { ...state, isWaitingApprovalOrRejection: true };
        case COMPLIANCE_ALERT_APPROVE_TRANSACTION_SUCCESS:
            return { ...state, isWaitingApprovalOrRejection: false };
        case COMPLIANCE_ALERT_CHANGE_STATUS_REQUEST:
            return { ...state, pendingItems: [action.payload], error: null };
        case COMPLIANCE_ALERT_CHANGE_STATUS_SUCCESS:
            return {
                ...state,
                approvedItems: [...state.approvedItems, action.payload],
            };
        case COMPLIANCE_ALERT_RESPONSE_APPROVAL_FAILURE:
            return {
                ...state,
                failedItems: [...state.failedItems, action.payload],
            };
        case COMPLIANCE_SCAN_CSV_DOWNLOAD_REQUEST:
            return { ...state, downloading: true, loading: true, error: null };
        case COMPLIANCE_SCAN_CSV_DOWNLOAD_SUCCESS:
            return {
                ...state,
                downloading: false,
            };
        case ERROR:
            return {
                downloading: false,
            };
        case RECEIVE_COMPLIANCE_ALERT_CHANGE_STATUS_REQUEST:{
            return { ...state, pendingItems: [action.payload], error: null };
        }
        case RECEIVE_COMPLIANCE_ALERT_CHANGE_STATUS_SUCCESS:
            return {
                ...state,
                approvedItems: [...state.approvedItems, action.payload],
            };
        default:
            return state;
    }
}
