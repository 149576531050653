import {
    CLEAR_ERROR,
    EDIT_ACCOUNT,
    EDIT_ACCOUNT_SUCCESS,
    EDIT_ACCOUNT_FAILURE,
    CREATE_ACCOUNT,
    CREATE_ACCOUNT_SUCCESS,
    CREATE_ACCOUNT_FAILURE,
    GET_ACCOUNT_SETTINGS_DATA,
    GET_ACCOUNT_SETTINGS_DATA_SUCCESS,
    GET_ACCOUNT_SETTINGS_DATA_FAILURE,
    DOWNLOAD_ACCOUNT_SETTINGS_DATA,
    DOWNLOAD_ACCOUNT_SETTINGS_DATA_SUCCESS,
    DOWNLOAD_ACCOUNT_SETTINGS_DATA_FAILURE,
    CREATE_ACCOUNTS, CREATE_ACCOUNTS_FAILURE,
    CREATE_ACCOUNTS_SUCCESS
} from '../../actions/types';

const initialState = {
    data: [],
    total: null,
    loading: false,
    error: null,
    downloadLoading: false,
    editState: {
        loading: false,
        success: false,
        error: null,
    },
    createState: {
        loading: false,
        success: false,
        error: null,
    },
    createAccountsState: {
        loading: false,
        error: null,
    },
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case CLEAR_ERROR: {
            return {
                ...state,
                [action.key]: { ...state[action.key], error: null },
            };
        }
        case EDIT_ACCOUNT: {
            return {
                ...state,
                editState: { ...state.editState, loading: true, error: null },
            };
        }
        case EDIT_ACCOUNT_SUCCESS: {
            return {
                ...state,
                editState: {
                    ...state.editState,
                    loading: false,
                    success: action.payload,
                },
            };
        }
        case EDIT_ACCOUNT_FAILURE: {
            return {
                ...state,
                editState: {
                    ...state.editState,
                    loading: false,
                    error: action.payload.message,
                },
            };
        }
        case CREATE_ACCOUNT: {
            return {
                ...state,
                createState: {
                    ...state.createState,
                    loading: true,
                    error: null,
                },
            };
        }
        case CREATE_ACCOUNT_SUCCESS: {
            return {
                ...state,
                createState: {
                    ...state.createState,
                    loading: false,
                    success: action.payload,
                },
            };
        }
        case CREATE_ACCOUNT_FAILURE: {
            return {
                ...state,
                createState: {
                    ...state.createState,
                    loading: false,
                    error: action.payload.message,
                },
            };
        }
        case CREATE_ACCOUNTS: {
            return {
                ...state,
                createAccountsState: {
                    loading: true,
                    error: null
                },
            };
        }
        case CREATE_ACCOUNTS_SUCCESS: {
            return {
                ...state,
                createAccountsState: {
                    loading: false
                },
            };
        }
        case CREATE_ACCOUNTS_FAILURE: {
            return {
                ...state,
                createAccountsState: {
                    loading: false,
                    error: action.payload,
                },
            };
        }
        case GET_ACCOUNT_SETTINGS_DATA: {
            return { ...state, loading: true, error: null };
        }
        case GET_ACCOUNT_SETTINGS_DATA_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload.data,
                total: action.payload.total,
            };
        }
        case GET_ACCOUNT_SETTINGS_DATA_FAILURE: {
            return { ...state, loading: false, error: action.payload.message };
        }
        case DOWNLOAD_ACCOUNT_SETTINGS_DATA: {
            return { ...state, downloadLoading: true, error: null };
        }
        case DOWNLOAD_ACCOUNT_SETTINGS_DATA_SUCCESS: {
            return { ...state, downloadLoading: false };
        }
        case DOWNLOAD_ACCOUNT_SETTINGS_DATA_FAILURE: {
            return {
                ...state,
                downloadLoading: false,
                error: action.payload.message,
            };
        }
        default:
            return state;
    }
}
