import {
    ROLE_SETTINGS_GET_ROLES_REQUEST,
    ROLE_SETTINGS_GET_ROLES_SUCCESS,
    ROLE_SETTINGS_GET_ROLES_FAILURE,
    ROLE_SETTINGS_CSV_REQUEST,
    ROLE_SETTINGS_CSV_SUCCESS,
    ROLE_SETTINGS_CSV_FAILURE,
    ROLE_SETTINGS_UPDATE_ROLE_REQUEST,
    ROLE_SETTINGS_UPDATE_ROLE_SUCCESS,
    ROLE_SETTINGS_UPDATE_ROLE_FAILURE,
    ROLE_SETTINGS_CREATE_ROLE_REQUEST,
    ROLE_SETTINGS_CREATE_ROLE_SUCCESS,
    ROLE_SETTINGS_CREATE_ROLE_FAILURE,
    CHANGE_PAGE_SIZE_REQUEST,
} from '../../actions/types';

const initialState = {
    pagination: {
        items: [],
    },
    loading: false,
    createLoading: false,
    downloading: false,
    refetchData: false,
    error: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case ROLE_SETTINGS_GET_ROLES_REQUEST:
            return { ...state, loading: true, refetchData: false, error: null };
        case ROLE_SETTINGS_GET_ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
                pagination: {
                    items: action.payload.response,
                    total: action.payload.recordsFiltered,
                },
            };
        case ROLE_SETTINGS_GET_ROLES_FAILURE:
            return { ...state, loading: false };
        case ROLE_SETTINGS_CSV_REQUEST:
            return { ...state, downloading: true, error: null };
        case ROLE_SETTINGS_CSV_SUCCESS:
            return { ...state, downloading: false };
        case ROLE_SETTINGS_CSV_FAILURE:
            return { ...state, downloading: false };
        case ROLE_SETTINGS_UPDATE_ROLE_REQUEST:
            return { ...state, createLoading: true, error: null };
        case ROLE_SETTINGS_UPDATE_ROLE_SUCCESS:
            return { ...state, createLoading: false, refetchData: true };
        case ROLE_SETTINGS_UPDATE_ROLE_FAILURE:
            return { ...state, createLoading: false };
        case ROLE_SETTINGS_CREATE_ROLE_REQUEST:
            return { ...state, createLoading: true, error: null };
        case ROLE_SETTINGS_CREATE_ROLE_SUCCESS:
            return { ...state, createLoading: false, refetchData: true };
        case ROLE_SETTINGS_CREATE_ROLE_FAILURE:
            return { ...state, createLoading: false };
        case CHANGE_PAGE_SIZE_REQUEST:
            return { ...state, loading: true, refetchData: true };
        default:
            return state;
    }
}
