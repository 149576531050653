export interface IMenuConfig {
    main?: string;
    name: string;
    url: string;
    mainUrl?: string;
}
export const addMenu = (menus: any[], config: IMenuConfig) => {
    const { main, name, url, mainUrl = '' } = config;
    /** check is menu with [name=main] already does not exist and also then menu with [name=name] also does not exist */
    const mainMenu = menus?.find((menu) => menu.name === main);
    const childrenMenu = mainMenu?.children?.find(
        (menu: IMenuConfig) => menu.name === name
    );
    const subMenu = {
        name,
        url,
    };
    if (mainMenu && !childrenMenu) {
        mainMenu.children.push(subMenu);
    } else if (!mainMenu) {
        menus.push({
            name: main,
            url: mainUrl,
            children: [subMenu],
        });
    }
};

const isOfOperation = (x: any) => !x.tags || x.tags.includes('operations');
export const renderMenu = (menulist: any[]) => {
    if (!menulist) {
        return { items: [] };
    }

    const items = menulist.filter(isOfOperation).map((branch) => ({
        name: branch.label,
        url: `/r${branch.url}`,
        children: branch.subMenus
            .filter(isOfOperation)
            .map((menuItem: any) => ({
                name: menuItem.label,
                url: `/r${menuItem.url}`,
            })),
    }));
    return { items };
};
