import {
    ACCOUNT_STATEMENT_DATA_REQUEST,
    ACCOUNT_STATEMENT_DATA_SUCCESS,
    ACCOUNT_STATEMENT_DATA_FAILURE,
    ACCOUNT_STATEMENT_ACCOUNT_INFORMATION_REQUEST,
    ACCOUNT_STATEMENT_ACCOUNT_INFORMATION_SUCCESS,
    ACCOUNT_STATEMENT_ACCOUNT_INFORMATION_FAILURE,
    ACCOUNT_STATEMENT_DOWNLOAD_REQUEST,
    ACCOUNT_STATEMENT_DOWNLOAD_SUCCESS,
    ACCOUNT_STATEMENT_DOWNLOAD_FAILURE,
    ACCOUNT_STATEMENT_ACCOUNTS_BY_CLIENT,
    ACCOUNT_STATEMENT_ACCOUNTS_BY_CLIENT_SUCCESS,
    ACCOUNT_STATEMENT_ACCOUNTS_BY_CLIENT_FAILURE,
    ACCOUNT_STATEMENT_FILTER_REQUEST,
} from '../../actions/types';

const initialState = {
    pagination: { items: [] },
    accounts: [],
    accountInformation: {},
    isClientChanging: false,
    loading: true,
    accountLoading: true,
    downloading: false,
    refetchData: false,
    error: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case ACCOUNT_STATEMENT_DATA_REQUEST:
            return { ...state, loading: true, refetchData: false, error: null };
        case ACCOUNT_STATEMENT_DATA_SUCCESS:
            return { ...state, loading: false, pagination: action.payload };
        case ACCOUNT_STATEMENT_DATA_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case ACCOUNT_STATEMENT_ACCOUNT_INFORMATION_REQUEST:
            return { ...state, accountLoading: true };
        case ACCOUNT_STATEMENT_ACCOUNT_INFORMATION_SUCCESS:
            return {
                ...state,
                accountInformation: action.payload.data,
                accountLoading: false,
            };
        case ACCOUNT_STATEMENT_ACCOUNT_INFORMATION_FAILURE:
            return { ...state, accountLoading: false };
        case ACCOUNT_STATEMENT_DOWNLOAD_REQUEST:
            return { ...state, downloading: true, error: null };
        case ACCOUNT_STATEMENT_DOWNLOAD_SUCCESS:
            return { ...state, downloading: false };
        case ACCOUNT_STATEMENT_DOWNLOAD_FAILURE:
            return { ...state, downloading: false };
        case ACCOUNT_STATEMENT_ACCOUNTS_BY_CLIENT:
            return { ...state, isClientChanging: true };
        case ACCOUNT_STATEMENT_ACCOUNTS_BY_CLIENT_SUCCESS:
            return {
                ...state,
                isClientChanging: false,
                accounts: action.payload.data,
            };
        case ACCOUNT_STATEMENT_ACCOUNTS_BY_CLIENT_FAILURE:
            return { ...state, isClientChanging: false, error: action.payload };
        case ACCOUNT_STATEMENT_FILTER_REQUEST:
            return { ...state, loading: true, refetchData: true };
        default:
            return state;
    }
}
