import {
    MALAYSIAN_REPORT_CSV_DOWNLOAD_REQUEST,
    MALAYSIAN_REPORT_CSV_DOWNLOAD_SUCCESS,
    MALAYSIAN_REPORT_CSV_DOWNLOAD_FAILURE,
} from '../../actions/types';

const initialState = {
    loading: false,
    error: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case MALAYSIAN_REPORT_CSV_DOWNLOAD_REQUEST:
            return { ...state, loading: true, error: null };
        case MALAYSIAN_REPORT_CSV_DOWNLOAD_SUCCESS:
            return { ...state, loading: false };
        case MALAYSIAN_REPORT_CSV_DOWNLOAD_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
}
