import { IReducerDataSlice } from '../../interface/IClient';
import { reconcileSliceType } from './reconcileSlice';
/** finops transactions */
type state = {
    finopsReview: reconcileSliceType;
};
export const selectTransactions = ({
    finopsReview,
}: state): IReducerDataSlice => finopsReview.transactions;
export const selectUnTaggedPaymentRequests = ({
    finopsReview,
}: state): IReducerDataSlice => finopsReview.unTaggedPaymentRequests;

export const selectFilter = ({ finopsReview }: state): any =>
    finopsReview.finopsFilter;

export const downloadTransaction = ({ finopsReview }: state): IReducerDataSlice =>
    finopsReview.downloadTransaction;