import axios from 'axios';
import { History } from 'history';
export const AxiosConfig = (history: History) => {
    axios.interceptors.request.use((config) => {
        if (!config.headers) {
            config.headers = {};
        }
        config.headers.csrfToken =
            localStorage.getItem('csrfToken') || undefined;
        config.headers['X-Requested-With'] = 'XMLHttpRequest';
        config.headers.SOURCE = 'operations';
        return config;
    });

    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            const concurrentUrl = '/concurrentlogin',
                loginUrl = '/login',
                logoutUrl = '/logout';
            const onConcurrentPage =
                history.location.pathname === concurrentUrl;
            const onLoginPage = history.location.pathname === loginUrl;
            if (error?.response?.data?.name === 'csrf_token_mismatch') {
                cleanUserFromLocalStorage();
                axios.get(logoutUrl);
                history.push(loginUrl, { from: history.location.pathname });
            } 
            if (error?.response?.data?.name === 'user_not_logged_in') {
                cleanUserFromLocalStorage();
                if (!onLoginPage && !onConcurrentPage) {
                    /**  if user is not loggedIn then page redirect him to login.
                     * remove csrfToken and login sessionId
                     */
                    const { state, pathname }: any = history.location;
                    const from = state?.from ?? pathname;
                    history.push(loginUrl, { from });
                }
            } else if (
                error?.response?.data?.name === 'concurrent_login' &&
                !onConcurrentPage
            ) {
                cleanUserFromLocalStorage();
                history.push(concurrentUrl);
            }
            return Promise.reject(error);
        }
    );
};
export const cleanUserFromLocalStorage = () => {
    localStorage.removeItem('csrfToken');
    localStorage.removeItem('user');
    localStorage.removeItem('currentAccount');
    localStorage.removeItem('root');
    localStorage.removeItem('isLoggedIn');
};
