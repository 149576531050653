import { createAsyncThunk } from '@reduxjs/toolkit';
import { get, post } from '../../../../../utils/request';
import { showSwalToast } from '../../../../../utils/toast';
import { thunkApiType } from '../../../../Interface';
import { getTransactions } from '../../Reconciliation/duck/getTransactions';
import { selectTransactions } from '../../Reconciliation/duck/reconcileSelector';
import { selectRfiDraft, selectRfisToSend, selectRfiClientId } from './RfiSelector';
import { RfiSliceType } from './RfiSlice';
import { selectUser } from '../../../../../../src/views/PayinCompliance/actions/selector';


export const sendRfi = createAsyncThunk(
    'sendRfi',
    async (arg, { dispatch, getState }: thunkApiType) => {
        const store: any = getState();
        const { name } = selectUser();
        const rfiDrafts: RfiSliceType['rfiDraft'][] = selectRfisToSend(store);
        let transactionId = '';
        const clientId = selectRfiClientId(store);
        const body = rfiDrafts.map((rfi) => {
            transactionId = rfi.transactionId;
            return {
                transactionId,
                requesterComment: rfi.requesterComment,
                documentTypeId: rfi.rfiDocumentType?.id,
                requestedBy: name,
            };
        });
        try {
            const result = await post({ url: `/client/${clientId}/rfi`, body });
            dispatch(getAllRfis(transactionId));
            const { requestBody } = selectTransactions(store);
            dispatch(getTransactions(requestBody));
            showSwalToast({
                title: 'Success',
                text: `RFI created successfully`,
                icon: 'success',
            });
            return result;
        } catch (error) {
            showSwalToast({
                title: 'Failure',
                text: `RFI was not created. Please try again`,
                icon: 'error',
            });
            return Promise.reject(error);
        }
    }
);

export const getAllRfis = createAsyncThunk(
    'getAllRfis',
    async (id: string, { getState }: thunkApiType) => {
        const store: any = getState();
        const clientId = selectRfiClientId(store);
        let transactionId = id;
        if (!id) {
            transactionId = selectRfiDraft(getState()).transactionId;
        }
        return get(`/client/${clientId}/rfi/transaction/${transactionId}`);
    }
);
