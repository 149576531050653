import { ComplianceAlertStatus, ComplianceHistoryStatus, ComplianceVerificationStatus } from "../../../utils/constants";

export type TStringKeyValue = {
    [property: string]: string;
};
type TTransaction = {
    status: TStringKeyValue;
    transactionSubStatus: TStringKeyValue;
    pageStatus: TStringKeyValue;
    rfiStatus: TStringKeyValue;
    amlLogStatus: TStringKeyValue;
    amlStatusText: TStringKeyValue;
    complianceStatusText: TStringKeyValue;
    statusClassName: TStringKeyValue;
    transactionUiStatusMapper: TStringKeyValue;
    mayHaveActiveRfi?: string[];
};

export const Transaction: TTransaction = {
    status: {
        TRANSACTION_RECEIVED: 'TRANSACTION_RECEIVED',
        COMPLIANCE_VERIFICATION: 'COMPLIANCE_VERIFICATION',
        COMPLIANCE_AUTO_APPROVED: 'COMPLIANCE_AUTO_APPROVED',
        COMPLIANCE_APPROVED: 'COMPLIANCE_APPROVED',
        COMPLIANCE_REJECTED: 'COMPLIANCE_REJECTED',
        COMPLETED: 'COMPLETED',
        TECHNICAL_ERROR: 'TECHNICAL_ERROR',
        CANCELLED: 'CANCELLED',
        PAYMENT_RECONCILIATION_PENDING: 'PAYMENT_RECONCILIATION_PENDING',
        COMPLIANCE_SCREENING: 'COMPLIANCE_SCREENING',
        COMPLIANCE_ERROR: 'COMPLIANCE_ERROR',
    },
    transactionSubStatus: {
        RFI_RAISED: 'RFI_RAISED',
        RFI_RESPONDED: 'RFI_RESPONDED',
    },
    pageStatus: {
        COMPLIANCE_HISTORY: 'COMPLIANCE_HISTORY',
        PAYMENT_RECONCILIATION_PENDING: 'PAYMENT_RECONCILIATION_PENDING',
        COMPLIANCE_VERIFICATION: 'COMPLIANCE_VERIFICATION',
        COMPLIANCE_ALERT: 'COMPLIANCE_ALERT',
    },
    rfiStatus: {
        RESPONDED: 'RESPONDED',
        RAISED: 'RAISED',
        REJECTED: 'REJECTED',
        APPROVED: 'APPROVED',
    },
    amlLogStatus: {
        HIT: 'HIT',
        CLEAR: 'CLEAR',
        ERROR: 'ERROR',
        TECHNICAL_ERROR: 'TECHNICAL_ERROR',
        COMPLIANCE_AUTO_APPROVED: 'COMPLIANCE_AUTO_APPROVED',
        AWAITING_RESPONSE: 'AWAITING_RESPONSE',
    },
    amlStatusText: {
        FAILED: 'Failed',
        SUCCESSFUL: 'Successful',
        ERROR: 'Error',
    },
    complianceStatusText: {
        IN_PROCESS: 'IN PROCESS',
        RFI_RAISED: 'RFI RAISED',
        RFI_RESPONDED: 'RFI RESPONDED',
        APPROVED: 'APPROVED',
        REJECTED: 'REJECTED',
        AUTO_APPROVED: 'AUTO APPROVED',
    },
    statusClassName: {
        HIT: 'hit',
        AWAITING: 'awaiting',
        ERROR: 'compliance-error',
        CLEAR: 'clear',
        SUCCESS: 'success',
        PENDING: 'pending',
        WARNING: 'warning',
    },
    transactionUiStatusMapper: {
        COMPLIANCE_SCREENING: 'COMPLIANCE SCREENING',
        COMPLIANCE_VERIFICATION: 'IN PROCESS',
        COMPLIANCE_ERROR: 'COMPLIANCE ERROR',
        TECHNICAL_ERROR: 'TECHNICAL ERROR',
        RFI_RAISED: 'RFI RAISED',
        RFI_RESPONDED: 'RFI RESPONDED',
    },
};
Transaction['mayHaveActiveRfi'] = [
    Transaction.status.COMPLIANCE_VERIFICATION,
    Transaction.status.COMPLIANCE_SCREENING,
    Transaction.status.PAYMENT_RECONCILIATION_PENDING,
    Transaction.status.COMPLIANCE_ERROR,
];

export const invalidStatusDescriptions = {
    payerName: ['Missing Payer Name', 'Invalid Payer Name'],
    bankCode: ['Missing Bank Code'],
    prId: ['Missing PRId', 'Invalid PRId', 'Payment reconciliation pending due to post transaction check skipped and prId is required']
}

/**
 * returns true if status is from Transaction.mayHaveActiveRfi and substatus is not null
 * @param status
 * @param subStatus
 */
export const getHasActiveRfi = (status: string, subStatus: string): boolean =>
    (Transaction['mayHaveActiveRfi']?.includes(status) ?? false) &&
    subStatus !== null;

/*
** these transaction statuses are not used in frontend, we are keeping in comments
only to know what all transactions statuses are there
 'TRANSACTION_RECEIVED',
  PAYMENT_RECONCILIATION_PENDING
 'PAYMENT_RECONCILIATION_APPROVED',//1, these transactions are not visible on UI
 'PAYMENT_RECONCILIATION_AUTO_APPROVED',//1 these transactions are not visible on UI
 'COMPLIANCE_VERIFICATION',  //
 'COMPLIANCE_AUTO_APPROVED', // this will go to gl entry of operations-dashboard
 'COMPLIANCE_APPROVED',// this will go to gl entry of operations-dashboard
 'COMPLIANCE_REJECTED',
 'SENT_TO_BANK_FOR_VERIFICATION', // sent to pp team
 'BANK_VERIFICATION_APPROVED', // gl entry for INR
 'BANK_VERIFICATION_REJECTED',
 'FINANCE_APPROVED', // action on gl entry
 'FINANCE_REJECTED',
 'COMPLETED', /** this status can be used when client marks the payment request as completed
 'TECHNICAL_ERROR',
 'CANCELLED',
 'COMPLIANCE_ERROR',
    */

export const statusMapping = {
    [Transaction.pageStatus.COMPLIANCE_HISTORY]: ComplianceHistoryStatus,
    [Transaction.pageStatus.COMPLIANCE_VERIFICATION]: ComplianceVerificationStatus,
    [Transaction.pageStatus.COMPLIANCE_ALERT]: ComplianceAlertStatus
};
