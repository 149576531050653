import {
    SAVE_RBCHECK_PARENT_STATE,
    CHECK_REPORT_DATA_FAILURE,
    CHECK_REPORT_DATA_REQUEST,
    CHECK_REPORT_DATA_SUCCESS,
    CHANGE_PAGE_SIZE_REQUEST,
    CHANGE_STATUS_SUCCESS,
    CHECK_SINGLE_REPORT_DATA_REQUEST,
    CHECK_SINGLE_REPORT_DATA_SUCCESS,
    GET_COMPLIANCE_STATUS_HISTORY_REQUEST,
    GET_COMPLIANCE_STATUS_HISTORY_SUCCESS,
    GET_RFIDOCSDETAILS_REQUEST,
    GET_RFIDOCSDETAILS_SUCCESS,
    GET_TRANSACTION_HISTORY_REQUEST,
    GET_TRANSACTION_HISTORY_SUCCESS,
    GET_TRANSACTION_HISTORY_FAILURE,
    CHANGE_HISTORY_PAGE_SIZE_REQUEST,
    SET_CURRENT_COMPLIANCE_LIST_TYPE
} from '../../actions/types';

const initialState = {
    pagination: { items: [] },
    historyPagination: { items: [] },
    loading: false,
    refetchData: false,
    historyRefetchData: false,
    loadingSingleCurrentInfo: '',
    loadingStatusHistoryTable: '',
    loadingPastInfoTable: '',
    loadingTransactionHistoryTable: '',
    error: null,
    reqCurrentInfo: [],
    reqSingleCurrentInfo: [],
    statusHistoryTable: [],
    previousRfiDocsDetails: [],
    transactionHistoryTable: [],
    parentState: null,
    currentComplianceType: null
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case SAVE_RBCHECK_PARENT_STATE:
            return { ...state, parentState: action.payload };
        case CHECK_REPORT_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                refetchData: false,
                error: action.payload.message,
            };
        case CHECK_REPORT_DATA_REQUEST:
            return { ...state, loading: true, refetchData: false, error: null };
        case CHECK_REPORT_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                pagination: {
                    items: action.payload.data.response,
                    total: action.payload.data.recordsFiltered,
                },
            };
        case CHANGE_PAGE_SIZE_REQUEST:
            return { ...state, loading: true, refetchData: true };
        case CHANGE_STATUS_SUCCESS:
            return { ...state,
                currentComplianceType: action.payload,
                refetchData: true 
            };
        case CHECK_SINGLE_REPORT_DATA_REQUEST:
            return {
                ...state,
                loadingSingleCurrentInfo: true,
                refetchData: false,
                error: null,
            };
        case CHECK_SINGLE_REPORT_DATA_SUCCESS:
            return {
                ...state,
                loadingSingleCurrentInfo: false,
                reqSingleCurrentInfo: action.payload,
            };
        case GET_COMPLIANCE_STATUS_HISTORY_REQUEST:
            return {
                ...state,
                loadingStatusHistoryTable: true,
                refetchData: false,
                error: null,
            };
        case GET_COMPLIANCE_STATUS_HISTORY_SUCCESS:
            return {
                ...state,
                loadingStatusHistoryTable: false,
                statusHistoryTable: action.payload.response,
            };
        case GET_RFIDOCSDETAILS_REQUEST:
            return { ...state, loadingPastInfoTable: true };
        case GET_RFIDOCSDETAILS_SUCCESS:
            return {
                ...state,
                loadingPastInfoTable: false,
                previousRfiDocsDetails: action.payload,
            };
        case GET_TRANSACTION_HISTORY_REQUEST:
            return {
                ...state,
                loadingTransactionHistoryTable: true,
                historyRefetchData: false,
                error: null,
            };
        case GET_TRANSACTION_HISTORY_SUCCESS:
            return {
                ...state,
                loadingTransactionHistoryTable: false,
                historyPagination: {
                    items: action.payload.data,
                    total: action.payload.recordsFiltered,
                },
            };
        case GET_TRANSACTION_HISTORY_FAILURE:
            return { ...state, loadingTransactionHistoryTable: false };
        case CHANGE_HISTORY_PAGE_SIZE_REQUEST:
            return {
                ...state,
                loadingTransactionHistoryTable: true,
                historyRefetchData: true,
            };
        case SET_CURRENT_COMPLIANCE_LIST_TYPE: 
            return {
                ...state,
                currentComplianceType: action.payload,
            }
        default:
            return state;
    }
}
