import { createSlice } from '@reduxjs/toolkit';
import { Action } from '../../../../Interface';
import { IReducerDataSlice } from '../../interface/IClient';
import { getTransactions } from './getTransactions';
import { getUntaggedPendingPaymentRequestForClient } from './getUntaggedPendingPaymentRequestForClient';
import { tagPaymentRequest } from './tagPaymentRequest';
import { ITransaction } from '../../interface/ITransaction';
import { downloadTransactions } from './downloadTransaction';
import moment from 'moment';
export type reconcileSliceType = {
    transactions: IReducerDataSlice;
    unTaggedPaymentRequests: IReducerDataSlice;
    tagPaymentRequest: IReducerDataSlice;
    finopsFilter: any;
    downloadTransaction: IReducerDataSlice;
};
let initialState: reconcileSliceType = {
    transactions: {
        isLoading: false,
        data: [],
        total: 0,
        requestBody: {},
        downloading: false
    },
    unTaggedPaymentRequests: {
        isLoading: false,
        data: [],
        total: 0,
        requestBody: {},
    },
    tagPaymentRequest: {
        isLoading: false,
        data: {},
        requestBody: {},
    },
    finopsFilter: {
        filterClient: {},
        filterCurrency: {},
        filterDate: {
            endDate: moment(),
            startDate: moment().subtract(7,'d'),
        },
        filterStatus: {},
        filterDateError: '',
        filterChanged: false,
        filterTransactionRefNo: '',
        filterPayerName: '',
        filterCountry: [],
        filterActionedBy: [],
        filterRiskType: {},
        filterSegment: [],
        filterVirtualAccountNumber: '',
        filterClientLegalEntity: [],
    },
    downloadTransaction: {
        data: [],
        total: 0,
    }
};
const slice = createSlice({
    name: 'finopsReview',
    initialState,
    reducers: {
        updateFilterClient(state, action) {
            state.finopsFilter.filterClient = action.payload;
            state.finopsFilter.filterChanged = true;
        },
        updateFilterCurrency(state, action) {
            state.finopsFilter.filterChanged = true;
            state.finopsFilter.filterCurrency = action.payload;
        },
        updateFilterStatus(state, action) {
            state.finopsFilter.filterChanged = true;
            state.finopsFilter.filterStatus = action.payload;
        },
        updateFilterDate(state, action) {
            state.finopsFilter.filterChanged = true;
            state.finopsFilter.filterDate.startDate =
                action.payload.startDate ||
                state.finopsFilter.filterDate.startDate;
            state.finopsFilter.filterDate.endDate =
                action.payload.endDate || state.finopsFilter.filterDate.endDate;
            state.finopsFilter.filterDateError = '';
        },
        updateFilterDateError(state, action) {
            state.finopsFilter.filterDateError = action.payload;
        },
        updateFilterPayerName(state, action) {
            state.finopsFilter.filterPayerName = action.payload;
            state.finopsFilter.filterChanged = true;
        },
        updateFilterTransactionRefNo(state, action) {
            state.finopsFilter.filterTransactionRefNo = action.payload;
            state.finopsFilter.filterChanged = true;
        },
        updateFilterCountry(state, action) {
            state.finopsFilter.filterCountry = action.payload;
            state.finopsFilter.filterChanged = true;
        },
        updateFilterActionedBy(state, action) {
            state.finopsFilter.filterActionedBy = action.payload;
            state.finopsFilter.filterChanged = true;
        },
        updateFilterRiskTypes(state, action) {
            state.finopsFilter.filterRiskType = action.payload;
            state.finopsFilter.filterChanged = true;
        },
        updateFilterSegment(state, action) {
            state.finopsFilter.filterSegment = action.payload;
            state.finopsFilter.filterChanged = true;
        },
        updateFilterVirtualAccountNumber(state, action) {
            state.finopsFilter.filterVirtualAccountNumber = action.payload;
            state.finopsFilter.filterChanged = true;
        },
        updateFilterClientLegalEntity(state, action) {
            state.finopsFilter.filterClientLegalEntity = action.payload;
            state.finopsFilter.filterChanged = true;
        },
        removeTransaction(state, action) {
            let index = state.transactions.data.findIndex(
                (transaction: ITransaction) => transaction.id === action.payload
            );
            if (index > -1 && typeof state.transactions.total === 'number') {
                state.transactions.data.splice(index, 1);
                state.transactions.total -= 1;
            }
        },
        resetFinopsFilter(state, action) {
            state.finopsFilter.filterClient = {};
            state.finopsFilter.filterDate = {
                endDate: moment(),
                startDate: moment().subtract(7,'d'),
            };
            state.finopsFilter.filterCurrency = {};
            state.finopsFilter.filterStatus = {};
            state.finopsFilter.filterChanged = true;
            state.finopsFilter.filterDateError = '';
            state.finopsFilter.filterPayerName = '';
            state.finopsFilter.filterTransactionRefNo = '';
            state.finopsFilter.filterCountry = [];
            state.finopsFilter.filterActionedBy = [];
            state.finopsFilter.filterRiskType = {};
            state.finopsFilter.filterSegment = [];
            state.finopsFilter.filterVirtualAccountNumber = '';
            state.finopsFilter.filterClientLegalEntity = [];
        },
        resetFilterChanged(state, action) {
            state.finopsFilter.filterChanged = false;
        },

        setTransactionToInitialStage(state, action){
            state.transactions=   {
                isLoading: false,
                data: [],
                total: 0,
                requestBody: {},
                downloading: false
            }
        },
    },
    extraReducers: {
        /** ---------- getTransactions ---------- */
        [getTransactions.pending.type]: (
            state: reconcileSliceType,
            action: Action<any>
        ) => {
            state.transactions.isLoading = true;
            state.transactions.requestBody = action.meta.arg;
        },
        [getTransactions.fulfilled.type]: (
            state: reconcileSliceType,
            action: Action<any>
        ) => {
            state.transactions.isLoading = false;
            state.transactions.data = action.payload?.data?.items ?? [];
            state.transactions.total = action.payload?.data?.totalRecords ?? 0;
            state.finopsFilter.filterChanged = false;
        },
        [getTransactions.rejected.type]: (
            state: reconcileSliceType,
            action: Action<any>
        ) => {
            state.transactions.isLoading = false;
            state.finopsFilter.filterChanged = false;
            // TODO: should we empty data as well?
        },
        /** ---------- getUntaggedPendingPaymentRequestForClient ---------- */
        [getUntaggedPendingPaymentRequestForClient.pending.type]: (
            state: reconcileSliceType,
            action: Action<any>
        ) => {
            state.unTaggedPaymentRequests.isLoading = true;
            state.unTaggedPaymentRequests.requestBody = action.meta.arg;
        },
        [getUntaggedPendingPaymentRequestForClient.fulfilled.type]: (
            state: reconcileSliceType,
            action: Action<any>
        ) => {
            state.unTaggedPaymentRequests = {
                isLoading: false,
                data: action.payload.data.filteredRecords,
                total: action.payload.data.totalRecords,
            };
        },
        [getUntaggedPendingPaymentRequestForClient.rejected.type]: (
            state: reconcileSliceType,
            action: Action<any>
        ) => {
            state.unTaggedPaymentRequests.isLoading = false;
            // TODO: should we empty data as well?
        },
        /** ---------- tagPaymentRequest ---------- */
        [tagPaymentRequest.pending.type]: (
            state: reconcileSliceType,
            action: Action<any>
        ) => {
            state.tagPaymentRequest.isLoading = true;
            state.tagPaymentRequest.requestBody = action.meta.arg;
        },
        [tagPaymentRequest.fulfilled.type]: (
            state: reconcileSliceType,
            action: Action<any>
        ) => {
            state.tagPaymentRequest = {
                isLoading: false,
                data: action.payload.data,
            };
        },
        [tagPaymentRequest.rejected.type]: (
            state: reconcileSliceType,
            action: Action<any>
        ) => {
            state.tagPaymentRequest = {
                isLoading: false,
                data: {},
            };
            // TODO: should we empty data as well?
        },
        [downloadTransactions.pending.type]: (
            state: reconcileSliceType,
            action: Action<any>
        ) => {
            state.transactions.downloading = true;
            state.downloadTransaction.requestBody = action.meta.arg;
        },
        [downloadTransactions.fulfilled.type]: (
            state: reconcileSliceType,
            action: Action<any>
        ) => {
            state.transactions.downloading = false;
            state.downloadTransaction.data = action.payload?.data?.items ?? [];
        },
        [downloadTransactions.rejected.type]: (
            state: reconcileSliceType,
        ) => {
            state.transactions.downloading = false;
            // TODO: should we empty data as well?
        },
        /** ---------- filterFinopsTransaction ---------- */
    },
});


export const { actions, reducer: finopsReview, name } = slice;
export const {
    resetFinopsFilter,
    updateFilterClient,
    updateFilterCurrency,
    updateFilterStatus,
    updateFilterDate,
    resetFilterChanged,
    removeTransaction,
    updateFilterDateError,
    updateFilterPayerName,
    updateFilterTransactionRefNo,
    updateFilterCountry,
    updateFilterActionedBy,
    updateFilterRiskTypes,
    updateFilterSegment,
    updateFilterVirtualAccountNumber,
    updateFilterClientLegalEntity,
    setTransactionToInitialStage,
} = actions;
