import axios from 'axios';
import { GET_SIDEBAR_DATA, SESSIONID_EXPIRED, TOGGLE_SIDEBAR } from './types';

export function getSidebarData() {
    return async function (dispatch) {
        try {
            const res = await axios.get('/user/menu');
            dispatch({
                type: GET_SIDEBAR_DATA,
                payload: res,
            });
        } catch (err) {
            localStorage.removeItem('csrfToken');
            dispatch({
                type: SESSIONID_EXPIRED,
            });
        }
    };
}

export function toggleSidebar() {
    return function (dispatch) {
        dispatch({
            type: TOGGLE_SIDEBAR,
        });
    };
}
