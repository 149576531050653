import axios from 'axios';
import {
    USER_LOGIN_LOADING,
    USER_LOGIN_SUCCESS,
    AUTH_ERROR,
    USER_AUTHENTICATION_SUCCESS,
    AUTH_GENCODE_ERROR,
    USER_LOGOUT,
    USER_FORGOT_PASSWORD_SUCCESS,
    AUTH_ERROR_FORGOT,
    USER_RESET_PASSWORD_SUCCESS,
    RESET_ERROR,
    HIDE_MESSAGES,
    ERROR,
} from './types';
import { setSession } from '../utils/request';
import { cleanUserFromLocalStorage } from '../init/AxiosConfig';
import {
    getClientListAll,
    getCommonConstants,
    getNonBPSPDefaultPricing,
    getAllClientWithRiskLevel,
} from './constants-actions';
import { getClientsList } from './header-actions';
import { getSidebarData } from './sidebar-actions';
import encryptData from '../utils/rsaEncryption';

export function login(values) {
    return async function (dispatch) {
        try {
            dispatch({
                type: USER_LOGIN_LOADING,
            });
            const res = await axios.post('/authentication/generateOTP', {
                ...values,
                pwd: encryptData(values.pwd),
                isEncrypted: true,
            });
            localStorage.setItem('csrfToken', res.data.csrfToken);
            dispatch({
                type: USER_LOGIN_SUCCESS,
                payload: res,
            });
            return true;
        } catch (err) {
            dispatch({
                type: AUTH_ERROR,
                payload: err.response.data,
            });
            return false;
        }
    };
}

export function loginWithOtp(values) {
    return async function (dispatch) {
        try {
            dispatch({
                type: USER_LOGIN_LOADING,
            });
            const res = await axios.post('/login', { ...values, pwd: encryptData(values.pwd), isEncrypted: true });
            const { user, token } = res.data.user;
            const { name, email, _id } = user;
            const { url } = user.homePage;
            localStorage.setItem('user', JSON.stringify({ name, email, _id }));
            localStorage.setItem('token', token);

            dispatch({
                type: USER_AUTHENTICATION_SUCCESS,
                payload: res.data.user.user,
            });
            localStorage.setItem('UserRootDirectory', url);

            dispatch(getCommonConstants());
            dispatch(getClientsList());
            dispatch(getClientListAll());
            dispatch(getSidebarData());
            dispatch(getNonBPSPDefaultPricing());
            dispatch(getAllClientWithRiskLevel());
            return true;
        } catch (err) {
            dispatch({
                type: AUTH_GENCODE_ERROR,
            });
            return false;
        }
    };
}

export function logout(callback) {
    return async function (dispatch) {
        setSession(false);
        try {
            cleanUserFromLocalStorage();
            dispatch({ type: USER_LOGOUT });
            await axios.get('/logout');
            return callback();
        } catch (err) {
            return callback();
        }
    };
}

export function forgotPasswordRequest(values) {
    return async function (dispatch) {
        try {
            dispatch({
                type: USER_LOGIN_LOADING,
            });
            const res = await axios.post('/requestPassword', values);
            dispatch({
                type: USER_FORGOT_PASSWORD_SUCCESS,
                payload: res,
            });
            return true;
        } catch (err) {
            dispatch({
                type: AUTH_ERROR_FORGOT,
            });
            return false;
        }
    };
}

export function resetPasswordRequest(values) {
    return async function (dispatch) {
        try {
            dispatch({
                type: USER_LOGIN_LOADING,
            });
            const res = await axios.post('/resetPassword', {
                resetToken: sessionStorage.getItem('resetToken'),
                password: encryptData(values.newPassword),
                isEncrypted: true,
            });
            dispatch({
                type: USER_RESET_PASSWORD_SUCCESS,
                payload: res,
            });
            return true;
        } catch (err) {
            dispatch({
                type: RESET_ERROR,
                payload: {
                    message: err?.response?.data?.message || null,
                },
            });
            return false;
        }
    };
}

export const resetMessages = () => ({ type: HIDE_MESSAGES });