import React from 'react';
import footerlogo from '../../assets/img/brand/footer_logo.png';
// TODO: remove reactstrap
export const DefaultFooter = () => {
    return (
        <footer className="app-footer">
            <div className="footer-logo">
                <img
                    className="navbar-brand"
                    src={footerlogo}
                    alt="MassPay"
                    height="auto"
                    width="126"
                />
            </div>
            <div className="footer-content">
                <span>Instarem Pte Ltd</span>
                <span>
                    Instarem Limited is licensed as a Money Service Operator and
                    is regulated in Hong Kong by the Hong Kong Customs Excise
                    Department. License No. 16-01-01797.
                </span>
                <span>
                    Copyright &copy; 2020 Instarem. All rights reserved.
                </span>
            </div>
        </footer>
    );
};
