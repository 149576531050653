import { IClient } from '../ComplianceTransaction/interface/IClient';

export const getActiveClientId = (state: any): string =>
    state.header?.activeClient?._id ?? '';

export const getReceiveComplianceClient = ({
    receiveCompliance,
}: any): IClient => receiveCompliance.client ?? [];

export const getReceiveComplianceAMLResponse = ({
    receiveCompliance,
}: any): any => {
    return receiveCompliance.amlResponse ?? [];
};

export const selectDocumentTypes = ({ receiveCompliance }: any): any =>
    receiveCompliance.documentTypes ?? [];

export const selectUser = (state?: any): any => {
    if (!state) return JSON.parse(localStorage.getItem('user') ?? '{}');
    const { user } = state;
    return {
        userId: user._id,
        name: user.name,
        email: user.email,
    };
};

export const selectClientId = ({ receiveCompliance }: any): string =>
    receiveCompliance.clientId;
