import React, { memo } from 'react';
import oopsIcon from '../assets/img/icons/oops.png';

/**
 * ErrorFallback component to be rendered by Error Boundary when some error occurs in the application
 * @param props
 */
const ErrorFallback = (props: any) => {
    const setPage = () => {
        props.history.goBack();
        setTimeout(() => {
            window.location.reload();
        }, 100);
    };

    const goToDashboard = () => {
        const dashboardUrl = localStorage.getItem('UserRootDirectory');
        window.location.href = `/r${dashboardUrl}`;
    };
    return (
        <div className="animated fadeIn errorBoundary">
            <div className="container center">
                <div className="flex flex-column">
                    <img src={oopsIcon} className="oopsIcon" alt="oopsIcon" />
                    <p className="mt-3">
                        Something went wrong. The page you are looking for is
                        temporarily unavailable.
                    </p>
                    <div className="mt-6">
                        <button
                            className="button-lg button-light-blue btn-hover-blue mr-3"
                            color="false"
                            onClick={() => setPage()}
                        >
                            Go To Previous Page
                        </button>
                        <button
                            className="button-lg bg-light-grey btn-hover-grey"
                            color="false"
                            onClick={() => goToDashboard()}
                        >
                            Go To Dashboard
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(ErrorFallback);
