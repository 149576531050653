import { AxiosError } from 'axios';
import { ERROR } from './types';
import { Action } from '../views/Interface';

export const getErrorAction = (err: AxiosError, message = ''): Action<any> => ({
    type: ERROR,
    payload: {
        message: err?.response?.data?.message ?? message,
    },
});
