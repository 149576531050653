import {
    REGULATORY_BLACKLIST,
    REGULATORY_BLACKLIST_FILES,
    REGULATORY_BLACKLIST_UPDATED,
} from '../../actions/types';

const initialState = {
    files: [],
    pagination: { items: [] },
    nationalities: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case REGULATORY_BLACKLIST:
            state.pagination = {
                items: action.payload.data.data || [],
                total: action.payload.data.recordsTotal,
                filtered: action.payload.data.recordsFiltered,
            };
            return state;
        case REGULATORY_BLACKLIST_FILES:
            state.files = Array.isArray(action.payload.data)
                ? action.payload.data
                : [];
            return state;
        case REGULATORY_BLACKLIST_UPDATED:
        default:
            return state;
    }
}
