import {
    USER_LOGIN_LOADING,
    USER_LOGIN_SUCCESS,
    USER_AUTHENTICATION_SUCCESS,
    AUTH_ERROR,
    AUTH_GENCODE_ERROR,
    HIDE_MESSAGES,
    USER_FORGOT_PASSWORD_SUCCESS,
    USER_RESET_PASSWORD_SUCCESS,
    RESET_ERROR,
    AUTH_ERROR_FORGOT,
} from '../actions/types';

const initialState = {
    loading: false,
    error: '',
    gencodeError: false,
    msg: null,
    url: '/r/',
    isDemoUser: false,
    user: {},
};

export default function (state = initialState, action = {}) {
    try {
        switch (action.type) {
            case USER_LOGIN_LOADING:
                return { ...state, loading: true, msg: null };
            case USER_LOGIN_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    isDemoUser: action.payload.data.status === 2 ? true : false,
                };
            case USER_AUTHENTICATION_SUCCESS:
                const newUrl = action.payload.generated_password_changed
                    ? `/r${action.payload.homePage.url}`
                    : '/r/settings/user/changePassword';
                return {
                    ...state,
                    loading: false,
                    user: action.payload,
                    url: newUrl,
                };
            case AUTH_ERROR:
                return {
                    ...state,
                    loading: false,
                    error: action.payload.message || 'Unexpected error ocurred',
                };
            case AUTH_GENCODE_ERROR:
                return {
                    ...state,
                    loading: false,
                    gencodeError: true,
                    error: 'Authentication error! Please check your dynammic access code and try again.',
                };
            case HIDE_MESSAGES:
                return {
                    ...state,
                    error: null,
                    gencodeError: false,
                    msg: null,
                };
            case USER_FORGOT_PASSWORD_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    msg: 'Thank you! If your account exists, a password reset link will be sent to you in a few moments.',
                };
            case USER_RESET_PASSWORD_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    msg: 'Your password has been changed successfully!',
                };
            case RESET_ERROR:
                return {
                    ...state,
                    loading: false,
                    error: action.payload.message,
                };
            case AUTH_ERROR_FORGOT:
                return {
                    ...state,
                    loading: false,
                    error: 'This email does not exist in our system. Please contact our support.',
                };
            default:
                return state;
        }
    } catch (error) {
        console.log('error in user-reducer', error);
    }
}
