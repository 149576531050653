export const getCountryFlag = (countryCode: string) => {
    try {
        return require(`../assets/img/currency-flag/${countryCode}.png`);
    } catch (err) {
        return require('../assets/img/currency-flag/ERROR.png');
    }
};
/**
 * adds space after every 4 numbers ==> 90909090 --> 9090 9090
 * @param accountNumber
 */
export const beautifyAccountNumber = (accountNumber: number | string) =>
    (accountNumber || '')
        .toString()
        .replace(/(.{4})/g, '$1 ')
        .trim();

export const capitalizeWord = (text: string) =>
    text.charAt(0).toUpperCase() + text.substring(1).toLowerCase();

export const capitalizeWords = (words: string): string => {
    if (typeof words !== 'string') return '';
    return words.split(' ').map(capitalizeWord).join(' ');
};
export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
    });
};
/**
 * howToDoThis ===> ["", "how", "To", "Do", "This"]
 * @param word word to be split
 */
export const splitCamelCaseWords = (word: string) => {
    if (typeof word !== 'string') return [];
    return word.replace(/([A-Z]+|[A-Z]?[a-z]+)(?=[A-Z]|\b)/g, '!$&').split('!');
};
/**
 * payin logger
 * @param data object
 */
export const log = (data: any) => {
    console.log('------------------logger------------------------');
    Object.entries(data || {}).forEach((t, i) => {
        console.log(`\n ${i}. \t ${t[0]} is ${JSON.stringify(t[1], null, 2)}`);
    });
    console.log('------------------logger------------------------');
};
