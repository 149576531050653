import { createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
interface IApiRequestState {
    latestErrorMessage: string;
    errorArray: string[];
}
const getErrorMessage = (error: AxiosError, message?: string): string => {
    return `Status Code: ${error?.response?.status ?? '-'}
    Status Text: ${error?.response?.statusText ?? '-'}
    API Error Message: ${error?.response?.data?.message ?? '-'}
    Application Error Message: ${message ?? '-'}`;
};

const slice = createSlice({
    name: 'apiRequest',
    initialState: {
        latestErrorMessage: 'No error',
        errorArray: [],
    },
    reducers: {
        apiRequestFailed: (state: IApiRequestState, action) => {
            const errorMessage: string = getErrorMessage(action.payload);
            console.log(errorMessage);
            state.errorArray.push(errorMessage);
            state.latestErrorMessage = errorMessage;
        },
    },
});

export const { reducer, actions, name } = slice;
export const { apiRequestFailed } = actions;
