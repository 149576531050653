import { CLEAR_ERROR, ERROR } from '../actions/types';

const initialState = null;

function formatErrorMessage(action) {
    return action.payload && action.payload.message === 'User not logged in'
        ? null
        : action.payload.message;
}

const errorReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case ERROR:
            return {
                message: formatErrorMessage(action),
                type: action.payload && action.payload.type,
            };
        case CLEAR_ERROR: {
            return null;
        }
        default:
            return state;
    }
};

export default errorReducer;
