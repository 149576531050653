import {
    USER_SETTINGS_GET_USERS_REQUEST,
    USER_SETTINGS_GET_USERS_SUCCESS,
    USER_SETTINGS_GET_USERS_FAILURE,
    USER_SETTINGS_CSV_REQUEST,
    USER_SETTINGS_CSV_SUCCESS,
    USER_SETTINGS_CSV_FAILURE,
    USER_SETTINGS_UPDATE_USER_REQUEST,
    USER_SETTINGS_UPDATE_USER_SUCCESS,
    USER_SETTINGS_UPDATE_USER_FAILURE,
    USER_SETTINGS_CREATE_USER_REQUEST,
    USER_SETTINGS_CREATE_USER_SUCCESS,
    USER_SETTINGS_CREATE_USER_FAILURE,
    CHANGE_PAGE_SIZE_REQUEST,
    CHANGE_PAGE_SIZE_REQUEST_COMPLETE
} from '../../actions/types';

const initialState = {
    pagination: {
        items: [],
    },
    loading: false,
    createLoading: false,
    downloading: false,
    refetchData: false,
    error: false,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case USER_SETTINGS_GET_USERS_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case USER_SETTINGS_GET_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                pagination: {
                    items: action.payload.data,
                    total: action.payload.recordsFiltered,
                },
            };
        case USER_SETTINGS_GET_USERS_FAILURE:
            return { ...state, loading: false };
        case USER_SETTINGS_CSV_REQUEST:
            return { ...state, downloading: true, error: false };
        case USER_SETTINGS_CSV_SUCCESS:
            return { ...state, downloading: false };
        case USER_SETTINGS_CSV_FAILURE:
            return { ...state, downloading: false };
        case USER_SETTINGS_UPDATE_USER_REQUEST:
            return { ...state, createLoading: true, error: false };
        case USER_SETTINGS_UPDATE_USER_SUCCESS:
            return { ...state, createLoading: false};
        case USER_SETTINGS_UPDATE_USER_FAILURE:
            return { ...state, createLoading: false };
        case USER_SETTINGS_CREATE_USER_REQUEST:
            return { ...state, createLoading: true, error: false };
        case USER_SETTINGS_CREATE_USER_SUCCESS:
            return { ...state, createLoading: false};
        case USER_SETTINGS_CREATE_USER_FAILURE:
            return { ...state, createLoading: false };
        case CHANGE_PAGE_SIZE_REQUEST:
            return { ...state, loading: true, refetchData: true };
        case CHANGE_PAGE_SIZE_REQUEST_COMPLETE:
            return { ...state, loading: false, refetchData: false };
        default:
            return state;
    }
}
