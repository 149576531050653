import {
    MANUAL_TRANSACTION_REQUEST,
    MANUAL_TRANSACTION_SUCCESS,
    MANUAL_TRANSACTION_FAILURE,
} from '../../actions/types';

const initialState = {
    transactionId: '',
    transactions: [],
    statusUpdated: false,
    loading: false,
    errorMessage: '',
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case MANUAL_TRANSACTION_REQUEST:
            return {
                ...state,
                loading: true,
                errorMessage: '',
                ...(action.payload||{})
            };
        case MANUAL_TRANSACTION_SUCCESS:
            return {
                ...state,
                loading: false,
                ...(action.payload || {})
            };
        case MANUAL_TRANSACTION_FAILURE:
            return {
                ...state,
                loading: false,
                ...(action.payload || {})
            };
        default:
            return state;
    }
}
