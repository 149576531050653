import axios, { AxiosError } from 'axios';
import { saveAs } from 'file-saver';
import * as types from '../constants/Types';
import { DispatchType } from '../../Interface';
import { createAction } from '@reduxjs/toolkit';
import { get, post } from '../../../utils/request';
import { showSwalToast } from '../../../utils/toast';
import { removeTransaction } from '../ComplianceTransaction/Reconciliation/duck/reconcileSlice';
import { selectDocumentTypes } from './selector';
import { getErrorAction } from '../../../actions/redux';
import { Moment } from 'moment';

export interface IComplianceApproveRejectAction {
    id: string;
    status: string;
    comment: string;
    updatedBy: string;
}
export interface ILegalEntity {
    label: string,
    value: string
}
export const approveRejectComplianceTransaction =
    (body: IComplianceApproveRejectAction) =>
    async (dispatch: DispatchType) => {
        try {
            await post({
                url: '/compliance-transaction/update',
                action: types.PAYIN_COMPLIANCE_APPROVE_REJECT_REQUEST,
                body,
            });
            showSwalToast({
                title: 'Success',
                text: `Transaction was successfully ${body.status.substr(11)}`,
                icon: 'success',
            });
            dispatch(removeTransaction(body.id));
            // TODO: Check if we need to remove the transaction from front-end or make a request to fetch all transactions again
        } catch (e) {
            showSwalToast({
                title: 'Failure',
                text: `Transaction could not be ${body.status.substr(11)}`,
                icon: 'error',
            });
        }
    };

const downloadDocument =
    ({ id, name, clientId }: { id: string; name: string; clientId: string }) =>
    async (dispatch: DispatchType) => {
        // TODO: unit test if this download document is working as expected
        try {
            const res = await axios.get(`/client/${clientId}/document/${id}`);
            const arrayBufferView = new Uint8Array(res.data.Body.data);
            const blob = new Blob([arrayBufferView]);
            saveAs(blob, name);
            return dispatch({
                type: types.PAYIN_COMPLIANCE_RFI_DOCUMENT_DOWNLOAD_SUCCESS,
            });
        } catch (err) {
            return dispatch(getErrorAction(err as AxiosError));
        }
    };

export const getDocumentTypesIfNeeded =
    () => async (dispatch: DispatchType, getState: any) => {
        try {
            if (selectDocumentTypes(getState()).length === 0) {
                const res = await get(
                    '/document-types',
                    types.PAYIN_RFI_DOCUMNET_TYPES_REQUEST
                );
                return dispatch({
                    type: types.PAYIN_RFI_DOCUMNET_TYPES_RECEIVE,
                    payload: res.data,
                });
            }
        } catch (err) {
            return dispatch(getErrorAction(err as AxiosError));
        }
    };

const fetchAmlResponseCheck =
    (transactionId: any) => async (dispatch: DispatchType) => {
        try {
            dispatch({
                type: types.PAYIN_AML_CHECKLIST_REQUEST,
            });
            const res = await axios.get(
                `/transaction/${transactionId}/amllogs`
            );
            dispatch({
                type: types.PAYIN_AML_CHECKLIST_RECEIVE,
                payload: res.data,
            });
        } catch (err) {
            dispatch(getErrorAction(err as AxiosError));
        }
    };

const fetchBeneficiaryDetail =
    (clientId: any) => async (dispatch: DispatchType) => {
        try {
            dispatch({
                type: types.PAYIN_CLIENT_DETAIL_REQUEST,
            });
            const res = await axios.get(`/client/${clientId}`);

            dispatch({
                type: types.PAYIN_CLIENT_DETAIL_RECEIVE,
                payload: res.data,
            });
        } catch (err) {
            dispatch(getErrorAction(err as AxiosError));
        }
    };

export const getReceiveTransactionAlerts = (params: {
    start?: number,
    limit?: number,
    currency: string,
    clientId: string,
    segment: string[],
    legalEntity: ILegalEntity[],
    status: string,
    updatedBy: string,
    startDate: Moment,
    endDate: Moment
    isCaas: boolean
}) => async (dispatch: DispatchType) => {
        try {
            dispatch({
                type: types.PAYIN_ALERT_TRANSACTION_LOADING,
            });
            const { 
                start= 0,
                limit = 10,
                currency,
                clientId,
                segment,
                legalEntity,
                status,
                updatedBy,
                startDate,
                endDate,
                isCaas,
            } = params;
            //Todo: Change Url after API is ready
            const clientSegment = !segment.length ? null : segment;
            const url = isCaas ? '/compliance/caasAlerts/payin' : '/transaction/alert/list';
            const response = await axios.post(url, isCaas ? {
                start,
                length: limit,
                ...(clientId ? {
                    clientId,
                } : {}),
                from_date: startDate,
                to_date: endDate,
                ...(status ? {
                    status
                } : {})
            } : {
                start,
                limit,
                ...(clientId ? {
                    clientId,
                } : {}),
                ...(currency && currency !== 'ALL' ? {
                    currency: currency
                } : {}),
                startDate: startDate?.utc()?.format('YYYY-MM-DD'),
                endDate: endDate?.utc()?.format('YYYY-MM-DD'),
                ...(status ? {
                    status: [status]
                } : {}),
                ...(clientSegment ? {
                    clientSegment
                } : {}),
                ...(legalEntity.length? {legalEntity}: {}),
                ...(updatedBy ? {
                    updatedBy,
                }: {} )                
            });
            dispatch({
                type: types.PAYIN_ALERT_TRANSACTION_RESPONSE,
                payload: response.data,
                isCaas
            });
        } catch (err) {
            dispatch({ type: types.PAYIN_ALERT_TRANSACTION_LOADING_DONE });
            dispatch(getErrorAction(err as AxiosError));
        }
    };

export const updateReceiveTransactionAlert = (params:{
    transactionId: string,
    newStatus: string,
    comment: string,
    updatedBy: string,
    updatedByName: string,
    isCaas: boolean
}) => async () => {
        try {
            const { 
                transactionId,
                newStatus,
                comment,
                updatedBy,
                updatedByName,
                isCaas
            } = params;
            await axios.post(isCaas ? '/compliance/caasAlert/updateStatus' :
            `/transaction/alert/${transactionId}`, isCaas ? {
                alertId: transactionId,
                status: newStatus,
                comment,
                updatedBy,
                updatedByName   
            } : {
                newStatus,
                comment,
                updatedBy,
                updatedByName   
            });
            showSwalToast({
                title: 'Success',
                text: `Changed Status of alert transaction to ${newStatus}`,
                icon: 'success',
            });
        } catch (err) {
            showSwalToast({
                title: 'Failure',
                text: `Unable to change status of alert transaction to ${params.newStatus}`,
                icon: 'error',
            });
        }
    };

export const setClientId = createAction<string>('setClientId');

export { downloadDocument, fetchAmlResponseCheck, fetchBeneficiaryDetail };
