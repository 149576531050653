import React from 'react';
import PropTypes from 'prop-types';

const InfoCard = ({ data, style, parentClassName, className }) => (
    <div id="InfoCard" className={`flex ${parentClassName}`}>
        <div className={`info-card flex mt-3 ${className}`} style={style}>
            {data.map((item, index) => (
                <div key={index} className="item">
                    <p className="title dark-blue">{item.title}</p>
                    <p className={`value dark-blue bold ${item.style}`}>
                        {item.value || '—'}
                    </p>
                </div>
            ))}
        </div>
    </div>
);

InfoCard.propTypes = {
    data: PropTypes.array,
    className: PropTypes.object,
};

InfoCard.defaultProps = {
    data: [],
    className: {},
};

export default InfoCard;
