import React, { Component } from 'react';
import PropTypes from 'prop-types';

class RadioButtons extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: props.selected || '',
        };
    }

    render() {
        const { selected } = this.state;
        const { data, className } = this.props;
        return (
            <div
                id="radioButtons"
                className={`flex user-type-selector ${className}`}
            >
                {data.map((item) => (
                    <div
                        className={`item vertical-center flex mr-5 ${
                            selected.value === item.value ? 'selected' : ''
                        }`}
                        onClick={() => {
                            this.setState({ selected: item });
                            this.props.onChange(item.value);
                        }}
                    >
                        <div className="option mr-3" />
                        <p>{item.label}</p>
                    </div>
                ))}
            </div>
        );
    }
}

RadioButtons.propTypes = {
    data: PropTypes.array,
};

RadioButtons.defaultProps = {
    data: [],
};

export default RadioButtons;
