import React from 'react';
import { Label } from 'reactstrap';

export const ErrorMessage = ({ message, className = '' }) =>
    !message ? null : (
        <p className={`msg-error mt-2 ${className}`}>{message}</p>
    );

export const Input = ({
    value,
    onChange,
    name,
    title,
    className = '',
    labelClassName = '',
    errorClassName = null,
    errorMessage = null,
    maxLength,
    type,
    readOnly=false,
    ...props
}) => (
    <div className={className}>
        {title !== 'disabled' && (
            <Label className={`title-h3-bold ${labelClassName}`}>{title}</Label>
        )}
        {readOnly ? (
            <div>{value || '-'}</div>
        ) : (
            <>
                <input
                    type={type}
                    maxLength={maxLength}
                    name={name}
                    className="form-control"
                    placeholder="..."
                    value={value}
                    onChange={onChange}
                    {...props}
                />
                <ErrorMessage
                    message={errorMessage}
                    className={errorClassName}
                />
            </>
        )}
    </div>
);
