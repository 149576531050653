export const PAYIN_COMPLIANCE_APPROVE_REJECT_REQUEST =
    'PAYIN_COMPLIANCE_APPROVE_REJECT_REQUEST';
export const PAYIN_RFI_DOCUMNET_TYPES_REQUEST =
    'PAYIN_RFI_DOCUMNET_TYPES_REQUEST';
export const PAYIN_RFI_DOCUMNET_TYPES_RECEIVE =
    'PAYIN_RFI_DOCUMNET_TYPES_RECEIVE';

export const PAYIN_PAYER_TYPE_INDIVIDUAL = 'INDIVIDUAL';
export const PAYIN_PAYER_TYPE_COMPANY = 'COMPANY';
export const PAYIN_SET_AVAILABLE_VRA = 'PAYIN_SET_AVAILABLE_VRA';

export const CREATE_PAYER_FAILURE = 'CREATE_PAYER_FAILURE';
export const PAYIN_ALL_PAYERS_FETCHED = 'PAYIN_ALL_PAYERS_FETCHED';
export const PAYIN_CURRENCY_RECEIVE = 'PAYIN_CURRENCY_RECEIVE';
export const PAYIN_CLIENT_CURRENCY_FETCHED = 'PAYIN_CLIENT_CURRENCY_FETCHED';

export const PAYIN_COUNTRY_RECEIVE = 'PAYIN_COUNTRY_RECEIVE';
export const PAYIN_VIRTUAL_RECEVING_ACCOUNT_RECEIVE =
    'PAYIN_VIRTUAL_RECEVING_ACCOUNT_RECEIVE';
export const CREATE_PAYER_REQUEST = 'CREATE_PAYER_REQUEST';

export const RESET_PAYMENT_REQUEST = 'RESET_PAYMENT_REQUEST';
export const HIDE_PAYMENT_REQUEST_POPUP = 'HIDE_PAYMENT_REQUEST_POPUP';
export const PAYIN_MAKE_PAYMENT_REQUEST = 'PAYIN_MAKE_PAYMENT_REQUEST';
export const PAYIN_MAKE_PAYMENT_REQUEST_SUCCESSFUL =
    'PAYIN_MAKE_PAYMENT_REQUEST_SUCCESSFUL';
export const PAYIN_MAKE_PAYMENT_REQUEST_ERRORED =
    'PAYIN_MAKE_PAYMENT_REQUEST_ERRORED';

export const PAYIN_GET_VIRTUAL_ACCOUNTS = 'PAYIN_GET_VIRTUAL_ACCOUNTS';
export const PAYIN_GET_VIRTUAL_ACCOUNTS_REQUEST =
    'PAYIN_GET_VIRTUAL_ACCOUNTS_REQUEST';
export const PAYIN_TRACK_PAYMENT_REQUEST_REPORT =
    'PAYIN_TRACK_PAYMENT_REQUEST_REPORT';
export const TRACK_PAYMENT_REQUEST_DATA_REQUEST =
    'TRACK_PAYMENT_REQUEST_DATA_REQUEST';
export const TRACK_PAYMENT_REQUEST_FILTER_GENERATE_REQUEST =
    'TRACK_PAYMENT_REQUEST_FILTER_GENERATE_REQUEST';
export const TRACK_PAYMENT_REQUEST_CSV_DOWNLOAD_SUCCESS =
    'TRACK_PAYMENT_REQUEST_CSV_DOWNLOAD_SUCCESS';
export const TRACK_PAYMENT_REQUEST_CSV_DOWNLOAD_REQUEST =
    'TRACK_PAYMENT_REQUEST_CSV_DOWNLOAD_REQUEST';
export const PAYMENT_REQUEST_SET_CURRENT_PAYER =
    'PAYMENT_REQUEST_SET_CURRENT_PAYER';
export const PAYIN_SET_IS_ENABLE_ADD_NEW_PAYER =
    'PAYIN_SET_IS_ENABLE_ADD_NEW_PAYER';

export const VRA_STATEMENT_REQUEST = 'VRA_STATEMENT_REQUEST';
export const VRA_STATEMENT_RECEIVE = 'VRA_STATEMENT_RECEIVE';
export const SET_VRA_CURRENCY_LOOKUP = 'SET_VRA_CURRENCY_LOOKUP';

export const PAYIN_WITHDRAW_FUND_CREATE_RESET =
    'PAYIN_WITHDRAW_FUND_CREATE_RESET';
export const PAYIN_WITHDRAW_FUND_CREATE_REQUEST =
    'PAYIN_WITHDRAW_FUND_CREATE_REQUEST';
export const PAYIN_WITHDRAW_FUND_CREATE_RECEIVE =
    'PAYIN_WITHDRAW_FUND_CREATE_RECEIVE';
export const PAYIN_WITHDRAW_FUND_HISTORY_REQUEST =
    'PAYIN_WITHDRAW_FUND_HISTORY_REQUEST';
export const PAYIN_WITHDRAW_FUND_HISTORY_RECEIVE =
    'PAYIN_WITHDRAW_FUND_HISTORY_RECEIVE';
export const WITHDRAW_FUND_FILTER_REQUEST = 'WITHDRAW_FUND_FILTER_REQUEST';
export const PAYIN_ACCOUNT_REQUEST = 'PAYIN_ACCOUNT_REQUEST';
export const PAYIN_ACCOUNT_RECEIVE = 'PAYIN_ACCOUNT_RECEIVE';

export const GET_VRAS_REQUEST = 'GET_VRAS_REQUEST';
export const GET_VRAS_SUCCESS = 'GET_VRAS_SUCCESS';
export const GET_VRAS_FAILURE = 'GET_VRAS_FAILURE';

// rfi
export const PAYIN_FETCH_TRANSACTION_RFI_DETAIL_REQUEST =
    'PAYIN_FETCH_TRANSACTION_RFI_DETAIL_REQUEST';
export const PAYIN_FETCH_TRANSACTION_RFI_DETAIL_SUCCESS =
    'PAYIN_FETCH_TRANSACTION_RFI_DETAIL_SUCCESS';
export const PAYIN_FETCH_TRANSACTION_RFI_DETAIL_FAILURE =
    'PAYIN_FETCH_TRANSACTION_RFI_DETAIL_FAILURE';

export const PAYIN_SUBMIT_ALL_RAISED_RFI_REQUESTS =
    'PAYIN_SUBMIT_ALL_RAISED_RFI_REQUESTS';
export const SET_NUMBER_OF_RFI_FOR_COMPLIANCE =
    'SET_NUMBER_OF_RFI_FOR_COMPLIANCE';
export const DOWNLOAD_RFI_CLIENT_DOCUMENT_REQUEST =
    'DOWNLOAD_RFI_CLIENT_DOCUMENT_REQUEST';
export const DOWNLOAD_RFI_CLIENT_DOCUMENT_SUCCESS =
    'DOWNLOAD_RFI_CLIENT_DOCUMENT_SUCCESS';
export const DOWNLOAD_RFI_CLIENT_DOCUMENT_FAILURE =
    'DOWNLOAD_RFI_CLIENT_DOCUMENT_FAILURE';
export const EMPTY_RFI_CLIENT_RESPONSE = 'EMPTY_RFI_CLIENT_RESPONSE';
export const CHANGE_TRANSACTION_COMPLIANCE_STATUS =
    'CHANGE_TRANSACTION_COMPLIANCE_STATUS';

export const PAYIN_COMPLIANCE_RFI_DOCUMENT_DOWNLOAD_SUCCESS =
    'PAYIN_COMPLIANCE_RFI_DOCUMENT_DOWNLOAD_SUCCESS';

export const PAYIN_VRA_CONFIGURATION_REQUEST =
    'PAYIN_VRA_CONFIGURATION_REQUEST';

export const PAYIN_AML_CHECKLIST_REQUEST = 'PAYIN_AML_CHECKLIST_REQUEST';
export const PAYIN_AML_CHECKLIST_RECEIVE = 'PAYIN_AML_CHECKLIST_RECEIVE';
export const PAYIN_CLIENT_DETAIL_REQUEST = 'PAYIN_CLIENT_DETAIL_REQUEST';
export const PAYIN_CLIENT_DETAIL_RECEIVE = 'PAYIN_CLIENT_DETAIL_RECEIVE';
export const ERROR = 'ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const PAYIN_TRANSACTION_UPDATED_BY = 'PAYIN_TRANSACTION_UPDATED_BY';
export const PAYIN_ALERT_TRANSACTION_LOADING = 'PAYIN_ALERT_TRANSACTION_LOADING';
export const PAYIN_ALERT_TRANSACTION_LOADING_DONE = 'PAYIN_ALERT_TRANSACTION_LOADING_DONE';
export const PAYIN_ALERT_TRANSACTION_RESPONSE = 'PAYIN_ALERT_TRANSACTION_RESPONSE';
