import {
    LOCKED_TRANSACTIONS_DATA_REQUEST,
    LOCKED_TRANSACTIONS_DATA_SUCCESS,
    LOCKED_TRANSACTIONS_DATA_FAILURE,
    LOCKED_TRANSACTIONS_REMOVE_LOCK_REQUEST,
    LOCKED_TRANSACTIONS_CHANGE_STATUS_REQUEST,
} from '../../actions/types';

const initialState = {
    pagination: { items: [] },
    loading: false,
    refetchData: false,
    error: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case LOCKED_TRANSACTIONS_DATA_REQUEST:
            return { ...state, loading: true, refetchData: false, error: null };
        case LOCKED_TRANSACTIONS_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                pagination: {
                    items: action.payload.data,
                    total: action.payload.data.length,
                },
            };
        case LOCKED_TRANSACTIONS_DATA_FAILURE:
            return { ...state, loading: false };
        case LOCKED_TRANSACTIONS_REMOVE_LOCK_REQUEST:
            return { ...state, loading: true, refetchData: true, error: null };
        case LOCKED_TRANSACTIONS_CHANGE_STATUS_REQUEST:
            return { ...state };
        default:
            return state;
    }
}
