import { reducer as formReducer } from 'redux-form';

import userReducer from '../reducers/user-reducer';
import errorReducer from '../reducers/errorReducer';
import headerReducer from '../reducers/headerReducer';
import sidebarReducer from '../reducers/sidebar-reducer';
import userInfoReducer from '../reducers/userInfo-reducer';
import constantsReducer from '../reducers/constants-reducer';
import statsReducer from '../reducers/page-reducers/stats-reducer';
import RBCheckReducer from '../reducers/page-reducers/RBCheck-reducer';
import GLEntryReducer from '../reducers/page-reducers/glentry-reducer';
import BatchGLEntryReducer from '../reducers/page-reducers/batchglentry-reducer';
import bankDetailsReducer from '../reducers/page-reducers/bankDetails-reducer';
import scheduledFXReducer from '../reducers/page-reducers/scheduledFX-reducer';
import manualPayoutReducer from '../reducers/page-reducers/manualPayout-reducer';
import complianceRfiReducer from '../reducers/page-reducers/complianceRFI-reducer';
import complianceAlertReducer from '../reducers/page-reducers/complianceAlert-reducer';
import financeSummaryReducer from '../reducers/page-reducers/financeSummary-reducer';
import complianceScanReducer from '../reducers/page-reducers/complianceScan-reducer';
import tieredPricingCalculatorReducer from '../reducers/tieredPricingCalculator-reducer';
import accountStatementReducer from '../reducers/page-reducers/accountStatement-reducer';
import malaysianReportsReducer from '../reducers/page-reducers/malaysianReports-reducer';
import reprocessPaymentsReducer from '../reducers/page-reducers/reprocessPayments-reducer';
import lockedTransactionsReducer from '../reducers/page-reducers/lockedTransactions-reducer';
import transactionHistoryReducer from '../reducers/page-reducers/transactionHistory-reducer';
import regulatoryBlacklistReducer from '../reducers/page-reducers/regulatoryBlacklist-reducer';
import manualPayoutConfirmationReducer from '../reducers/page-reducers/manualPayoutConfirmation-reducer';
import onfidoIdentificationReducer from '../reducers/page-reducers/onfidoIdentification-reducer';
import conversionFunnelReducer from '../reducers/page-reducers/conversionFunnel-reducer';
import onfidoPageReducer from '../reducers/page-reducers/onfidoPage-reducer';
import { receiveCompliance } from '../views/PayinCompliance/reducers/compliance-reducer';
import payinReducer from '../views/PayinCompliance/reducers/payin-reducer';
import withdrawFundReducer from '../views/PayinCompliance/reducers/withdrawfund-reducer';
import complianceManagementReducer from '../reducers/page-reducers/complianceManagement-reducer';
import complianceReportReducer from '../reducers/complianceReportReducer';
import roleSettingsReducer from '../reducers/page-reducers/roleSettings-reducer';
import accountSettingsReducer from '../reducers/page-reducers/accountSettings-reducer';
import userSettingsReducer from '../reducers/page-reducers/userSettings-reducer';
import clientSettingsReducer from '../reducers/page-reducers/clientSettings-reducer';
import currencySettingsReducer from '../reducers/page-reducers/currencySettings-reducer';
import { finopsReview } from '../views/PayinCompliance/ComplianceTransaction/Reconciliation/duck/reconcileSlice';
import { info } from '../reducers/infoSlice';
import { rfiReducer } from '../views/PayinCompliance/ComplianceTransaction/Rfi/duck/RfiSlice';
import paymentAlertReducer from '../reducers/page-reducers/paymentAlert-reducer';
import commentsReducer from '../reducers/page-reducers/comments-reducer';
import rejetectedICCReducer from '../reducers/page-reducers/iccRejections-reducer'
import inwardCreditConfirmationReducer from "../reducers/page-reducers/inwardCreditConfirmation-reducer";
import ManualTransactionReducer from "../reducers/page-reducers/manualtransaction-reducer";
import auditLogsReducer from "../reducers/auditlogs-reducer";

export const reducerConfig = {
    user: userReducer,
    form: formReducer,
    error: errorReducer,
    stats: statsReducer,
    header: headerReducer,
    sidebar: sidebarReducer,
    userInfo: userInfoReducer,
    constants: constantsReducer,
    auditLogs: auditLogsReducer,

    // Page reducers
    RBCheck: RBCheckReducer,
    GLEntry: GLEntryReducer,
    ManualTransaction: ManualTransactionReducer,
    BatchGLEntry: BatchGLEntryReducer,
    bankDetails: bankDetailsReducer,
    scheduledFX: scheduledFXReducer,
    manualPayout: manualPayoutReducer,
    complianceRfi: complianceRfiReducer,
    complianceAlert: complianceAlertReducer,
    complianceScan: complianceScanReducer,
    financeSummary: financeSummaryReducer,
    malaysianReports: malaysianReportsReducer,
    accountStatement: accountStatementReducer,
    reprocessPayments: reprocessPaymentsReducer,
    transactionHistory: transactionHistoryReducer,
    lockedTransactions: lockedTransactionsReducer,
    regulatoryBlacklist: regulatoryBlacklistReducer,
    tieredPricingCalculator: tieredPricingCalculatorReducer,
    manualPayoutConfirmation: manualPayoutConfirmationReducer,
    conversionFunnel: conversionFunnelReducer,
    onfidoIdentification: onfidoIdentificationReducer,
    onfido: onfidoPageReducer,
    receiveCompliance,
    payin: payinReducer,
    payinWithdrawFund: withdrawFundReducer,
    complianceManagement: complianceManagementReducer,
    complianceReport: complianceReportReducer,
    roleSettings: roleSettingsReducer,
    accountSettings: accountSettingsReducer,
    currencySettings: currencySettingsReducer,
    clientSettings: clientSettingsReducer,
    userSettings: userSettingsReducer,
    comments: commentsReducer,
    finopsReview,
    info,
    rfiReducer,
    paymentAlertReducer,
    rejectedICC: rejetectedICCReducer,
    inwardCreditConfirmation: inwardCreditConfirmationReducer
};
