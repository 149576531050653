import axios from 'axios';
import {
    SELECT_ACTIVE_RELOAD,
    SELECT_ACTIVE_CLIENT,
    GET_USER_CLIENTS_LIST_REQUEST,
    GET_USER_CLIENTS_LIST_SUCCESS,
    ERROR,
} from './types';
/* shouldPreventRerender is true, when client is not change, and false otherwise */
export function clientSelect(selectedClient, shouldPreventRerender = false) {
    return async function (dispatch) {
        if (!shouldPreventRerender) {
            dispatch({ type: SELECT_ACTIVE_RELOAD });
        }
        setImmediate(() => {
            return dispatch({
                type: SELECT_ACTIVE_CLIENT,
                payload: selectedClient,
            });
        });
    };
}

export function getClientsList() {
    return async function (dispatch) {
        try {
            dispatch({
                type: GET_USER_CLIENTS_LIST_REQUEST,
            });
            const res = await axios.get('/user/info');
            dispatch({
                type: GET_USER_CLIENTS_LIST_SUCCESS,
                payload: res,
            });
            dispatch(clientSelect(res.data?.client_access?.[0], true));
        } catch (err) {
            dispatch({
                type: ERROR,
                payload: {
                    message:
                        (err &&
                            err.response &&
                            err.response.data &&
                            err.response.data.message) ||
                        null,
                },
            });
        }
    };
}
