import React from 'react';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { toggleSidebar } from '../../actions/sidebar-actions';
import { logout } from '../../actions/user-actions';

import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import { AppHeaderDropdown } from '@coreui/react';
import hamburger from '../../assets/img/icons/hamburger-menu.svg';
import { scrollToTop } from '../../utils/payinUtils';
import dashlogo from '../../assets/img/brand/dashlogo.png';
import avatar from '../../assets/img/avatar/avatar.png';
const actions = {
    toggleSidebar,
    logout,
};

const DefaultHeader = (props) => {
    const { sidebar, toggleSidebar: toggle, logout: performLogout } = props;
    const history = useHistory();
    const onLogout = () => {
        performLogout(() => {
            history.push('/login');
        });
    };
    const rootUrl = localStorage.getItem('UserRootDirectory');
    return (
        <div data-test="header" className="app-header navbar">
            <img
                alt="navigation-menu-icon"
                data-test="sidebar-toggler"
                className="show-sidebar-toggler"
                src={hamburger}
                onClick={toggle}
            />
            <Link
                data-test="dashlink"
                to={`/r${rootUrl}`}
                className="navbar-brand"
                onClick={scrollToTop}
            >
                <img
                    data-test="dashlogo"
                    src={dashlogo}
                    className="navbar-brand-full"
                    style={{ width: 89, height: 26 }}
                    alt="MassPay"
                />
            </Link>
            <Nav className="ml-auto d-md-down-none" navbar>
                <NavItem className="px-3">
                    <NavLink
                        data-test="bankDetailsLink"
                        onClick={() => {
                            history.push('/r/settings/user/BankDetails');
                            scrollToTop();
                        }}
                    >
                        Bank Details
                    </NavLink>
                </NavItem>
                <NavItem className="px-3">
                    <NavLink
                        data-test="userManualLink"
                        href="/common/userManual"
                        target="blank"
                    >
                        User Manual
                    </NavLink>
                </NavItem>
            </Nav>
            <Nav navbar data-test="userMenu">
                <AppHeaderDropdown direction="down">
                    <DropdownToggle nav className="username px-3">
                        <div className="account-info">
                            <img src={avatar} className="img-avatar" alt="" />
                            <div
                                data-test="userNameDisplay"
                                className="account-name"
                            >
                                {sidebar.data &&
                                sidebar.data.user_profile &&
                                sidebar.data.user_profile.name
                                    ? `${sidebar.data.user_profile.name}`
                                    : 'Menu'}
                            </div>
                            <i className="fa fa-chevron-down"></i>
                        </div>
                    </DropdownToggle>
                    <DropdownMenu right style={{ right: 'auto' }}>
                        <DropdownItem>
                            <img src={avatar} className="img-avatar" alt="" />
                            <NavLink
                                data-test="profileLink"
                                href="/r/settings/user/myProfile"
                            >
                                Profile
                            </NavLink>
                        </DropdownItem>
                        <DropdownItem data-test="logout" onClick={onLogout}>
                            Logout
                        </DropdownItem>
                    </DropdownMenu>
                </AppHeaderDropdown>
            </Nav>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        sidebar: state.sidebar,
        clientsList: state.header.clientsList || [],
        activeClient: state.header.activeClient,
    };
};

export default connect(mapStateToProps, actions)(DefaultHeader);
