import * as types from '../constants/Types';

const initialState = {
    withdrawFundCreateLoading: false,
    withdrawFund: [],
    withdrawFundResponse: {},
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.PAYIN_WITHDRAW_FUND_CREATE_RESET: {
            return Object.assign({}, state, {
                withdrawFundCreateLoading: false,
                withdrawFundResponse: {},
            });
        }
        case types.PAYIN_WITHDRAW_FUND_CREATE_REQUEST: {
            return Object.assign({}, state, {
                withdrawFundCreateLoading: true,
            });
        }

        case types.PAYIN_WITHDRAW_FUND_CREATE_RECEIVE: {
            return Object.assign({}, state, {
                withdrawFundResponse: action.withdrawFundResponse,
                withdrawFundCreateLoading: false,
            });
        }
        case types.PAYIN_WITHDRAW_FUND_HISTORY_REQUEST: {
            return Object.assign({}, state, {
                withdrawFundHistoryLoading: true,
            });
        }

        case types.PAYIN_WITHDRAW_FUND_HISTORY_RECEIVE: {
            return Object.assign({}, state, {
                withdrawFund: action.withdrawFund,
                withdrawFundHistoryLoading: false,
            });
        }

        default:
            return state;
    }
}
