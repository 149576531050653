import {
    GET_SIDEBAR_DATA,
    SESSIONID_EXPIRED,
    TOGGLE_SIDEBAR,
} from '../actions/types';

const initialState = {
    data: '',
    sidebarStatus: '',
    showSidebar: false,
    clientSettingsReadOnlyAccess: false,
    userSettingsReadOnlyAccess: false,
    accountSettingsReadOnlyAccess: false,
};


export default function (state = initialState, action = {}) {
    switch (action.type) {
        case GET_SIDEBAR_DATA:
            return {
                ...state,
                data: action.payload.data,
                clientSettingsReadOnlyAccess: action?.payload?.data?.roles?.some(role => role.toLowerCase() === 'client_setting_readonly') ?? false,
                userSettingsReadOnlyAccess: !action?.payload?.data?.roles?.some(role => role.toLowerCase() === 'user_settings') ?? false,
                accountSettingsReadOnlyAccess: action?.payload?.data?.roles?.some(role => role.toLowerCase() === 'account_settings_readonly') ?? false,
            };
        case SESSIONID_EXPIRED:
            return { sidebarStatus: 'SESSIONID_EXPIRED' };
        case TOGGLE_SIDEBAR:
            return { ...state, showSidebar: !state.showSidebar };
        default:
            return state;
    }
}

export const toggleReadOnlyStyle = (add) => {
    const body = document.querySelector('.app-body');
    const dashboard = document.querySelector(
        '.app-body .container-fluid .dashboard-view'
    );
    if (add) {
        body.style.cssText = 'margin-top: 55px !important';
        dashboard.style.cssText = 'border-radius:0';
    } else {
        body.style.cssText = '';
        dashboard.style.cssText = '';
    }
};
