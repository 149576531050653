import { GET_USER_INFO_SUCCESS } from '../actions/types';

const initialState = {
    clients: [],
};

export default function (state = initialState, action = {}) {
    if (action.type === GET_USER_INFO_SUCCESS) {
        return { clients: action.payload.data.client_access };
    }
    return state;
}
