// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react';
import '../scss/components/_button.scss';
import { IEvent } from '../views/Interface';
interface IButton {
    onClick(event: IEvent): void;
    title: string;
    lightBlue?: boolean;
    hoverBlue?: boolean;
    disabled?: boolean;
    size?: string;
    className?: string;
    id?: string;
}
export const Button = (props: IButton) => {
    const {
        onClick,
        size = 'lg',
        lightBlue = false,
        hoverBlue = false,
        title,
        className = '',
        id,
        disabled = false,
    } = props;
    return (
        <div className={`button-container ${className}`}>
            <button
                className={`${`button-${size}`}
                ${lightBlue ? 'button-light-blue' : 'bg-light-grey'}
                ${hoverBlue && 'btn-hover-blue'}`}
                onClick={onClick as any}
                disabled={disabled}
                id={id}
            >
                {title}
            </button>
        </div>
    );
};

export default Button;
