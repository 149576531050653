import nodeRsa from 'node-rsa';
import { getConfig } from './config';
export default data => {
    const rsaKey =  getConfig('REACT_APP_RSA_KEY');
    const nodeRsaPublicKey = nodeRsa(
        rsaKey,
        'pkcs8-public',
        { environment: 'browser' }
    );
    return nodeRsaPublicKey.encrypt(data, 'base64');
};
