import React, { Component } from 'react';
import Select from 'react-select';
import Multi from './multi-select';
import { Label } from 'reactstrap';

const SingleSelect = ({
    options,
    onChange,
    selected,
    className,
    dataName = '',
    title,
    statePropName,
    id,
    disabled,
    errorMessage,
    errorClassName,
    readOnly,
    ...props
}) => {
    return (
        <div
            data-test={dataName}
            className={`select-item-transparent ${className}`}
        >
            <Label className="title-h3-bold" for="account">
                {title}
            </Label>
            {readOnly ? (
                <div>{selected?.label || '-'}</div>
            ) : (
                <>
                    <Select
                        id={id}
                        className={`__select data-test-${dataName}`}
                        classNamePrefix="select"
                        placeholder="Choose"
                        closeMenuOnSelect
                        value={selected}
                        onChange={(obj) => onChange(obj, statePropName)}
                        options={options}
                        isDisabled={disabled}
                        {...props}
                    />
                    {errorMessage && (
                        <p className={`msg-error mt-2 ${errorClassName}`}>
                            {errorMessage}
                        </p>
                    )}
                </>
            )}
        </div>
    );
};
class MultiSelect extends Component {
    componentDidMount() {
        Array.from(document.getElementsByClassName('multi-select')).forEach(
            (el) => {
                el.getElementsByClassName('dropdown')[0].removeAttribute(
                    'tabindex'
                );
            }
        );
    }

    render() {
        const {
            title,
            options,
            value,
            onChange,
            className,
            dataName = '',
            disabled,
            readOnly,
        } = this.props;
        const readOnlyLabel = [];
        for (let i = 0; i < options?.length; i++) {
            if (value.includes(options[i].value)) {
                readOnlyLabel.push(options[i].label);
            }
        }
        return (
            <div data-test={dataName} className={className}>
                <Label className="title-h3-bold mb-3">{title}</Label>
                {readOnly ? (
                    <div style={{ whiteSpace: 'pre-line' }}>
                        {readOnlyLabel.join('\n ') || '-'}
                    </div>
                ) : (
                    <Multi
                        overrideStrings={{
                            selectSomeItems: 'Choose',
                            allItemsAreSelected: 'All',
                            selectAll: 'All',
                        }}
                        options={options}
                        selected={value}
                        onSelectedChanged={(v) => onChange(v)}
                        disabled={disabled}
                        {...this.props}
                    />
                )}
            </div>
        );
    }
}

export { SingleSelect, MultiSelect };
