import {
    FINANCE_SUMMARY_DATA_REQUEST,
    FINANCE_SUMMARY_DATA_SUCCESS,
    FINANCE_SUMMARY_DATA_FAILURE,
    FINANCE_SUMMARY_CSV_DOWNLOAD_REQUEST,
    FINANCE_SUMMARY_CSV_DOWNLOAD_SUCCESS,
    FINANCE_SUMMARY_CSV_DOWNLOAD_FAILURE,
    FINANCE_SUMMARY_FILTER_GENERATE_REQUEST,
} from '../../actions/types';

const initialState = {
    pagination: { items: [] },
    loading: false,
    downloading: false,
    refetchData: false,
    error: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case FINANCE_SUMMARY_DATA_REQUEST:
            return { ...state, loading: true, refetchData: false, error: null };
        case FINANCE_SUMMARY_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                pagination: {
                    items: action.payload.data.data,
                    total: action.payload.data.recordsTotal,
                    filtered: action.payload.data.recordsFiltered,
                },
            };
        case FINANCE_SUMMARY_DATA_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case FINANCE_SUMMARY_CSV_DOWNLOAD_REQUEST:
            return { ...state, downloading: true, error: null };
        case FINANCE_SUMMARY_CSV_DOWNLOAD_SUCCESS:
            return { ...state, downloading: false };
        case FINANCE_SUMMARY_CSV_DOWNLOAD_FAILURE:
            return { ...state, downloading: false, error: action.payload };
        case FINANCE_SUMMARY_FILTER_GENERATE_REQUEST:
            return { ...state, loading: true, refetchData: true, error: null };
        default:
            return state;
    }
}
