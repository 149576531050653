import { ITrackPaymentRequestBody } from '../../interface/ITrackPaymentRequestBody';
import { thunkApiType } from '../../../../Interface';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { post } from '../../../../../utils/request';
import { selectUnTaggedPaymentRequests } from './reconcileSelector';

export const getUntaggedPendingPaymentRequestForClient = createAsyncThunk(
    'getPendingUntaggedPaymentRequestForClient',
    async (body: ITrackPaymentRequestBody, { getState }: thunkApiType) => {
        let data = body;
        if (!data.clientId) {
            data = selectUnTaggedPaymentRequests(getState()).requestBody;
        }
        const response = post({
            url: '/payment-request/track-payment-request',
            body: data,
        });
        return response;
    }
);
