import {
    ERROR,
    PAYMENT_ALERT_DATA_REQUEST,
    PAYMENT_ALERT_DATA_SUCCESS,
    PAYMENT_ALERT_DATA_FAILURE,
    PAYMENT_ALERT_CLIENT_SUCCESS
} from '../../actions/types';

const initialState = {

};
export default function (state = initialState, action = {}) {
    switch (action.type) {
        case PAYMENT_ALERT_DATA_REQUEST:
            return { ...state, loading: true, error: null };
        case PAYMENT_ALERT_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                pagination: {
                    items: action.payload.data.data,
                    total: action.payload.data.length,
                },
            };
        case PAYMENT_ALERT_DATA_FAILURE:
            return { ...state, loading: false };
        case PAYMENT_ALERT_CLIENT_SUCCESS: 
            return { ...state, loading: false, alertClients: action.payload.data.data };
        case ERROR:
            return {
                downloading: false,
            };
        default:
            return state;
    }
}
