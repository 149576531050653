import React from 'react';
import PropTypes from 'prop-types';

const Error = ({ message, optionalClass }) =>
    !message ? null : (
        <div className={`error-component ${optionalClass}`}>
            <i className="fa fa-exclamation-circle" />
            {message}
        </div>
    );

Error.propTypes = {
    message: PropTypes.string.isRequired,
};

export default Error;
