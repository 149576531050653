import {
    GET_COUNTRIES_SUCCESS,
    GET_COUNTRIES_FAILURE,
    GET_TIMEZONES_SUCCESS,
    GET_TIMEZONES_FAILURE,
    CLEAR_ERROR,
    EDIT_CURRENCY,
    EDIT_CURRENCY_SUCCESS,
    EDIT_CURRENCY_FAILURE,
    CREATE_CURRENCY,
    CREATE_CURRENCY_SUCCESS,
    CREATE_CURRENCY_FAILURE,
    GET_CURRENCY_SETTINGS_DATA,
    GET_CURRENCY_SETTINGS_DATA_SUCCESS,
    GET_CURRENCY_SETTINGS_DATA_FAILURE,
} from '../../actions/types';

const initialState = {
    data: [],
    total: null,
    loading: false,
    error: null,
    timezones: [],
    countries: {},
    editState: {
        loading: false,
        success: false,
        error: null,
    },
    createState: {
        loading: false,
        success: false,
        error: null,
    },
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case GET_COUNTRIES_SUCCESS: {
            return { ...state, countries: action.payload };
        }
        case GET_COUNTRIES_FAILURE: {
            return { ...state, error: action.payload.message };
        }
        case GET_TIMEZONES_SUCCESS: {
            return { ...state, timezones: action.payload };
        }
        case GET_TIMEZONES_FAILURE: {
            return { ...state, error: action.payload.message };
        }
        case CLEAR_ERROR: {
            return {
                ...state,
                [action.key]: { ...state[action.key], error: null },
            };
        }
        case EDIT_CURRENCY: {
            return {
                ...state,
                editState: { ...state.editState, loading: true, error: null },
            };
        }
        case EDIT_CURRENCY_SUCCESS: {
            return {
                ...state,
                editState: {
                    ...state.editState,
                    loading: false,
                    success: action.payload,
                },
            };
        }
        case EDIT_CURRENCY_FAILURE: {
            return {
                ...state,
                editState: {
                    ...state.editState,
                    loading: false,
                    error: action.payload.message,
                },
            };
        }
        case CREATE_CURRENCY: {
            return {
                ...state,
                createState: {
                    ...state.createState,
                    loading: true,
                    error: null,
                },
            };
        }
        case CREATE_CURRENCY_SUCCESS: {
            return {
                ...state,
                createState: {
                    ...state.createState,
                    loading: false,
                    success: action.payload,
                },
            };
        }
        case CREATE_CURRENCY_FAILURE: {
            return {
                ...state,
                createState: {
                    ...state.createState,
                    loading: false,
                    error: action.payload.message,
                },
            };
        }
        case GET_CURRENCY_SETTINGS_DATA: {
            return { ...state, loading: true, error: null };
        }
        case GET_CURRENCY_SETTINGS_DATA_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload.data,
                total: action.payload.total,
            };
        }
        case GET_CURRENCY_SETTINGS_DATA_FAILURE: {
            return { ...state, loading: false, error: action.payload.message };
        }
        default:
            return state;
    }
}
