import {
    REJECTED_ICC_DATA_REQUEST,
    REJECTED_ICC_DATA_SUCCESS,
    CHANGE_PAGE_SIZE_REQUEST,
    ERROR,
} from '../../actions/types';

const initialState = {
    pagination: { items: [] },
    loading: false,
    error: null,
};
export default function (state = initialState, action = {}) {
    switch (action.type) {
        case REJECTED_ICC_DATA_REQUEST:
            return { ...state, loading: true, error: null };
        case REJECTED_ICC_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                pagination: {
                    items: action.payload.data.response,
                    total: action.payload.data.recordsFiltered,
                },
            };
        case CHANGE_PAGE_SIZE_REQUEST:
            return { ...state, loading: true };
        case ERROR:
            return {
                downloading: false,
            };
        default:
            return state;
    }
}
