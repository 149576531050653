export const urls = {
    '/user/info': 'NA',
    '/r/dashboard/client': [],
    '/r/dashboard/finance': [],
    '/r/analytics/complianceReport': [],
    '/r/analytics/conversionFunnel': [],
    '/r/defaultDashboard': [],
    '/r/dashboard/compliance': [],
    '/r/dashboard/operations': [],
    '/r/dashboard/systemadmin': [],
    '/r/settings/user/changePassword': [],
    '/r/settings/user/bankDetails': [],
    '/r/reports/tieredPricingCalculator': ['/reports/tieredPricingCalculator'],
    '/r/compliance/compliancerfi': ['/compliance/compliancerfi'],
    '/r/compliance/complianceDocumentUpload': [
        '/compliance/complianceDocumentUpload',
    ],
    '/r/compliance/complianceScan': ['/compliance/complianceScan'],
    '/r/compliance/regulatoryBlacklist': ['/compliance/regulatoryblacklist'],
    '/r/compliance/beneficiarycheck': ['/compliance/beneficiarycheck'],
    '/r/compliance/beneficiarycheck/view': ['/compliance/beneficiarycheck'],
    '/r/compliance/onfido': ['/compliance/onfido'],
    '/r/compliance/onfidoSummary': ['/compliance/onfido'],
    '/r/compliance/complianceManagement': ['/compliance/complianceManagement'],
    '/r/settings/user/myProfile': ['/settings/user/myProfile'],
    '/r/settings/role': ['/settings/role'],
    '/r/settings/account': ['/settings/account'],
    '/r/settings/user': ['/settings/user'],
    '/r/settings/currency': ['/settings/currency'],
    '/r/settings/client': ['/settings/client'],
    '/r/operations/accountstatement': ['/operations/accountStatement'],
    '/r/operations/transactionHistory': ['/operations/transactionHistory'],
    '/r/operations/stats': ['/operations/stats'],
    '/r/operations/glentry': ['/operations/glentry'],
    '/r/operations/scheduled-fx': ['/operations/scheduled-fx'],
    '/r/operations/malaysiaReports': ['/operations/malaysiaReports'],
    '/r/operations/reprocesspayments': ['/operations/reprocesspayments'],
    '/r/operations/financeSummary': ['/operations/financeSummary'],
    '/r/compliance/transactionInProcess': ['/compliance/transactionInProcess'],
    '/r/compliance/transactionHistory': ['/compliance/transactionHistory'],
    '/r/operations/lockedTransactions': ['/operations/lockedTransactions'],
    '/r/operations/manualpayouttransactions': [
        '/operations/manualpayouttransactions',
    ],
    '/r/operations/manualpayout': ['/operations/manualpayout'],
    '/r/operations/receivePaymentReconciliation': [
        '/operations/receivePaymentReconciliation',
    ],
    '/r/compliance/compliancealert': [
        '/compliance/compliancealert',
    ],
    '/r/compliance/rejectedIcc': [
        '/compliance/rejectedIcc',
    ],
    '/r/transaction/statusUpdate': ['/transaction/statusUpdate']
};
