import {
    GET_USER_CLIENTS_LIST_SUCCESS,
    USER_PROFILE_DATA_REQUEST,
    USER_PROFILE_DATA_SUCCESS,
    USER_PROFILE_CHANGE_PASSWORD_REQUEST,
    USER_PROFILE_CHANGE_PASSWORD_SUCCESS,
    USER_PROFILE_CHANGE_PASSWORD_FAILURE,
} from '../actions/types';

const initialState = {
    loading: false,
    loadingChangePwd: false,
    client_access: [],
    userProfile: [],
    receivePost: undefined,
    statusMessageSuccess: '',
    statusMessageFailure: '',
    generated_password_changed: undefined,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case GET_USER_CLIENTS_LIST_SUCCESS:
            return {
                ...state,
                client_access: action.payload.data.client_access,
                generated_password_changed:
                    action.payload.data.generated_password_changed,
            };
        case USER_PROFILE_DATA_REQUEST:
            return { ...state, loading: true };
        case USER_PROFILE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                userProfile: action.payload,
                receivePost: action.payload.userSettings.postPaidEmailAlerts,
            };
        case USER_PROFILE_CHANGE_PASSWORD_REQUEST:
            return { ...state, loadingChangePwd: true };
        case USER_PROFILE_CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                loadingChangePwd: false,
                statusMessageSuccess:
                    'Your password has been changed successfully!',
                statusMessageFailure: '',
            };
        case USER_PROFILE_CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                loadingChangePwd: false,
                statusMessageSuccess: '',
                statusMessageFailure: action.payload.message,
            };
        default:
            return state;
    }
}
