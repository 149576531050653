import {
    GET_AVAILABLE_COUNTRIES,
    GET_AVAILABLE_COUNTRIES_SUCCESS,
    GET_AVAILABLE_COUNTRIES_FAILURE,
    GET_ONFIDO_APPLICANTS,
    GET_ONFIDO_APPLICANTS_SUCCESS,
    GET_ONFIDO_APPLICANTS_FAILURE,
    SAVE_SELECTED_APPLICANT,
    CREATE_ONFIDO_APPLICANT,
    CREATE_ONFIDO_APPLICANT_SUCCESS,
    CREATE_ONFIDO_APPLICANT_FAILURE,
    GET_ONFIDO_APPLICANT_DETAILS,
    GET_ONFIDO_APPLICANT_DETAILS_SUCCESS,
    GET_ONFIDO_APPLICANT_DETAILS_FAILURE,
} from '../../actions/types';

const initialState = {
    data: [],
    totalRecords: 0,
    loading: false,
    error: null,
    selectedApplicant: null,
    isRegistrationInProgress: false,
    isGettingApplicantDetails: false,
    fetchingCountries: false,
    availableCountries: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case GET_AVAILABLE_COUNTRIES:
            return { ...state, fetchingCountries: true };
        case GET_AVAILABLE_COUNTRIES_SUCCESS:
            return {
                ...state,
                fetchingCountries: false,
                availableCountries: action.payload,
            };
        case GET_AVAILABLE_COUNTRIES_FAILURE:
            return {
                ...state,
                fetchingCountries: false,
                error: action.payload.message,
            };
        case GET_ONFIDO_APPLICANTS:
            return { ...state, loading: true, error: null };
        case GET_ONFIDO_APPLICANTS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.data,
                totalRecords: action.payload.total,
            };
        case GET_ONFIDO_APPLICANTS_FAILURE:
            return { ...state, loading: false, error: action.payload.message };
        case SAVE_SELECTED_APPLICANT:
            return { ...state, selectedApplicant: action.payload };
        case CREATE_ONFIDO_APPLICANT:
            return { ...state, isRegistrationInProgress: true, error: null };
        case CREATE_ONFIDO_APPLICANT_SUCCESS:
            return { ...state, isRegistrationInProgress: false };
        case CREATE_ONFIDO_APPLICANT_FAILURE:
            return {
                ...state,
                isRegistrationInProgress: false,
                error: action.payload.message,
            };
        case GET_ONFIDO_APPLICANT_DETAILS:
            return { ...state, isGettingApplicantDetails: true };
        case GET_ONFIDO_APPLICANT_DETAILS_SUCCESS:
            return {
                ...state,
                isGettingApplicantDetails: false,
                selectedApplicant: Object.assign(
                    {},
                    state.selectedApplicant,
                    action.payload
                ),
            };
        case GET_ONFIDO_APPLICANT_DETAILS_FAILURE:
            return {
                ...state,
                isGettingApplicantDetails: false,
                error: action.payload.message,
            };
        default:
            return state;
    }
}
