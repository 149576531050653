// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { lazy, Suspense } from 'react';
// routes config
import { Route, Switch } from 'react-router-dom';
import { loggedInRoutes } from '../../init/routes';
import { Spinner } from '../../components';

const ChangePassword = lazy(() => import('../Pages/PasswordChange/PasswordChange'));
interface Props {
    generated_password_changed: boolean;
    isClientChanging: boolean;
}
export const RootContainer = (props: Props) => {
    const { generated_password_changed, isClientChanging } = props;
    /** we specifically need to check if we get exact false value from server and then only show the <ChangePassword /> component */
    if (generated_password_changed === false) return <ChangePassword />;
    if (generated_password_changed && !isClientChanging) {
        return (
            <div className="container-fluid">
                 <Suspense fallback={<Spinner type="global" />}>
                    <Switch>
                        {loggedInRoutes.map((route: any, idx) => {
                            const { path, exact, /* name, */ Component } = route;
                            return Component ? (
                                <Route
                                    // name={name} TODO: find a fix for no overload matches this call
                                    key={idx}
                                    path={path}
                                    exact={exact}
                                    render={(routeProps) => (
                                        <Component {...routeProps} />
                                    )}
                                />
                            ) : null;
                        })}
                    </Switch>
                </Suspense>
            </div>
        );
    }
    return (<Spinner type="global" />);
};
