import {
    INWARD_CREDIT_CONFIRMATION_FX_DATA_REQUEST,
    INWARD_CREDIT_CONFIRMATION_DATA_SUCCESS,
    INWARD_CREDIT_CONFIRMATION_DATA_FAILURE,
    INWARD_CREDIT_CONFIRMATION_CSV_DOWNLOAD_REQUEST,
    INWARD_CREDIT_CONFIRMATION_CSV_DOWNLOAD_SUCCESS,
    INWARD_CREDIT_CONFIRMATION_CSV_DOWNLOAD_FAILURE,
    GET_CLIENT_ACCOUNT_NUMBER_DATA_SUCCESS,
    APPROVE_FAILED_ICC, CREATE_PAYIN_ICC
} from '../../actions/types';

const initialState = {
    pagination: { items: [] },
    loading: false,
    refetchData: false,
    error: null,
    accountData: null,
    createPayinIcc: {
        loading: false,
        errorMessage: '',
        iccSimulated: false
    }
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case INWARD_CREDIT_CONFIRMATION_FX_DATA_REQUEST:
            return { ...state, loading: true, refetchData: false, error: null };
        case INWARD_CREDIT_CONFIRMATION_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                pagination: {
                    items: action.payload.data.data.recordsFiltered,
                    total: action.payload.data.data.recordsTotal,
                },
            };
        case INWARD_CREDIT_CONFIRMATION_DATA_FAILURE:
            return { ...state, loading: false };
        case INWARD_CREDIT_CONFIRMATION_CSV_DOWNLOAD_REQUEST:
            return { ...state, downloading: true, refetchData: false, error: null };
        case INWARD_CREDIT_CONFIRMATION_CSV_DOWNLOAD_SUCCESS:
            return { ...state, downloading: false, refetchData: false, error: null };
        case INWARD_CREDIT_CONFIRMATION_CSV_DOWNLOAD_FAILURE:
            return { ...state, downloading: false };
        case GET_CLIENT_ACCOUNT_NUMBER_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                accountData: action.payload?.data?.data,
            };
        case APPROVE_FAILED_ICC:
            return {
                ...state,
                loading: false,
                accountData: action.payload?.success,
            };
        case CREATE_PAYIN_ICC:
            return {
                ...state,
                createPayinIcc: action.payload
            };
        default:
            return state;
    }
}
