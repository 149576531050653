import * as types from '../constants/Types';
import { setClientId } from '../actions/compliance-actions';
import { IClient } from '../ComplianceTransaction/interface/IClient';
interface IComplianceReducer {
    documentTypesLoading: boolean;
    documentTypes: any[];
    clientLoading: boolean;
    client: IClient;
    amlResponseLoading: boolean;
    amlResponse: any[];
    clientId: string;
    transactionAlerts: Object,
    transactionCaasAlerts: Object,
    transactionAlertLoading: boolean,
}
const initialState: IComplianceReducer = {
    documentTypesLoading: false,
    documentTypes: [],
    clientLoading: false,
    client: {
        client_address: '',
        client_city: '',
        client_country: '',
        client_label: '',
        client_name: '',
    },
    amlResponseLoading: false,
    amlResponse: [],
    clientId: '',
    transactionAlerts: {},
    transactionCaasAlerts: {},
    transactionAlertLoading: false
};

export const receiveCompliance = (
    state: IComplianceReducer = initialState,
    action: any
): IComplianceReducer => {
    switch (action.type) {
        case types.PAYIN_RFI_DOCUMNET_TYPES_REQUEST: {
            return { ...state, documentTypesLoading: true };
        }

        case types.PAYIN_RFI_DOCUMNET_TYPES_RECEIVE: {
            return {
                ...state,
                documentTypesLoading: false,
                documentTypes: action.payload,
            };
        }

        case types.PAYIN_CLIENT_DETAIL_REQUEST: {
            return { ...state, clientLoading: true };
        }

        case types.PAYIN_CLIENT_DETAIL_RECEIVE: {
            return {
                ...state,
                clientLoading: false,
                client: action.payload,
            };
        }

        case types.PAYIN_AML_CHECKLIST_REQUEST: {
            return { ...state, amlResponseLoading: true };
        }

        case types.PAYIN_AML_CHECKLIST_RECEIVE: {
            return {
                ...state,
                amlResponseLoading: false,
                amlResponse: action.payload,
            };
        }
        case [setClientId]: {
            return {
                ...state,
                clientId: action.payload,
            };
        }
        case types.PAYIN_ALERT_TRANSACTION_LOADING: {
            return {
                ...state,
                transactionAlertLoading: true,
            };
        }
        case types.PAYIN_ALERT_TRANSACTION_RESPONSE: {
            return action.isCaas ? {
                ...state,
                transactionAlertLoading: false,
                transactionCaasAlerts: action.payload,
            } : {
                ...state,
                transactionAlertLoading: false,
                transactionAlerts: action.payload,
            };
        }
        case types.PAYIN_ALERT_TRANSACTION_LOADING_DONE: {
            return {
                ...state,
                transactionAlertLoading: false,
                
            };
        }
        default:
            return state;
    }
};
