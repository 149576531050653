import React, { Component } from 'react';
import ErrorFallback from './ErrorFallback';
export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }
    componentDidCatch() {
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            return <ErrorFallback {...this.props} />;
        }
        return this.props.children;
    }
}
