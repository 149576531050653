import {
    ERROR,
    CLEAR_ERROR,
    CLIENT_SETTINGS_GET_CLIENTS_REQUEST,
    CLIENT_SETTINGS_GET_CLIENTS_SUCCESS,
    CLIENT_SETTINGS_GET_CLIENTS_FAILURE,
    CLIENT_SETTINGS_GET_PARTNER_REQUEST,
    CLIENT_SETTINGS_GET_PARTNER_SUCCESS,
    CLIENT_SETTINGS_GET_PARTNER_FAILURE,
    CLIENT_SETTINGS_CSV_REQUEST,
    CLIENT_SETTINGS_CSV_SUCCESS,
    CLIENT_SETTINGS_CSV_FAILURE,
    CLIENT_SETTINGS_UPDATE_CLIENT_REQUEST,
    CLIENT_SETTINGS_UPDATE_CLIENT_SUCCESS,
    CLIENT_SETTINGS_UPDATE_CLIENT_FAILURE,
    CLIENT_SETTINGS_CREATE_CLIENT_REQUEST,
    CLIENT_SETTINGS_CREATE_CLIENT_SUCCESS,
    CLIENT_SETTINGS_CREATE_CLIENT_FAILURE,
    CLIENT_SETTINGS_CALLBACK_ACTION,
    CHANGE_PAGE_SIZE_REQUEST,
    CHANGE_PAGE_SIZE_REQUEST_COMPLETE,
} from '../../actions/types';

const getClientSuccessObject = (state) => {
    return {
        ...state,
        createLoading: false,
        error: null,
    };
};

export default function (
    state = {
        pagination: {
            items: [],
        },
        partners: [],
        loading: false,
        createLoading: false,
        downloading: false,
        refetchData: false,
        error: null,
        configLoading: false,
        configErrorMessage: '',
        config: {}
    },
    action = {}
) {
    switch (action.type) {
        case CLEAR_ERROR:
            return { ...state, error: null };
        case CLIENT_SETTINGS_GET_CLIENTS_REQUEST:
        case CLIENT_SETTINGS_GET_PARTNER_REQUEST:
            return { ...state, loading: true };
        case CLIENT_SETTINGS_GET_CLIENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                pagination: {
                    items: action.payload.response,
                    total: action.payload.recordsFiltered,
                },
            };
        case CLIENT_SETTINGS_GET_CLIENTS_FAILURE:
        case CLIENT_SETTINGS_GET_PARTNER_FAILURE:
            return { ...state, loading: false };
        case CLIENT_SETTINGS_GET_PARTNER_SUCCESS:
            return {
                ...state,
                loading: false,
                partners: action.payload,
            };
        case CLIENT_SETTINGS_CSV_REQUEST:
            return { ...state, downloading: true };
        case CLIENT_SETTINGS_CSV_SUCCESS:
            return { ...state, downloading: false, error: null };
        case CLIENT_SETTINGS_CSV_FAILURE:
            return { ...state, downloading: false };
        case CLIENT_SETTINGS_UPDATE_CLIENT_REQUEST:
            return { ...state, createLoading: true };
        case CLIENT_SETTINGS_UPDATE_CLIENT_FAILURE:
            return { ...state, createLoading: false };
        case CLIENT_SETTINGS_CREATE_CLIENT_REQUEST:
            return { ...state, createLoading: true };
        case CLIENT_SETTINGS_UPDATE_CLIENT_SUCCESS:
        case CLIENT_SETTINGS_CREATE_CLIENT_SUCCESS:
            return getClientSuccessObject(state);
        case CLIENT_SETTINGS_CREATE_CLIENT_FAILURE:
            return { ...state, createLoading: false };
        case CHANGE_PAGE_SIZE_REQUEST:
            return { ...state, loading: true, refetchData: true };
        case CHANGE_PAGE_SIZE_REQUEST_COMPLETE:
            return { ...state, loading: false, refetchData: false };
        case ERROR:
            return {
                ...state,
                loading: false,
                downloading: false,
                error: action.payload,
            };
        case CLIENT_SETTINGS_CALLBACK_ACTION:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
