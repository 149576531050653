import {
    TRANSACTION_HISTORY_DATA_REQUEST,
    TRANSACTION_HISTORY_DATA_SUCCESS,
    TRANSACTION_HISTORY_DATA_FAILURE,
    TRANSACTION_HISTORY_CSV_DOWNLOAD_REQUEST,
    TRANSACTION_HISTORY_CSV_DOWNLOAD_SUCCESS,
    TRANSACTION_HISTORY_CSV_DOWNLOAD_FAILURE,
    TRANSACTION_HISTORY_GENARATE_RECEIPT_REQUEST,
    TRANSACTION_HISTORY_GENARATE_RECEIPT_SUCCESS,
    TRANSACTION_HISTORY_GENARATE_RECEIPT_FAILURE,
    TRANSACTION_HISTORY_FILTER_GENERATE_REQUEST,
    TRANSACTION_HISTORY_CANCEL_TRANSACTION_FAILURE,
    TRANSACTION_HISTORY_POPRECALL_REQUEST,
    TRANSACTION_HISTORY_POPRECALL_RESPONSE,
    TRANSACTION_HISTORY_CAAS_DETAILS_API
} from '../../actions/types';

const initialState = {
    pagination: {
        items: [],
    },
    loading: false,
    downloading: false,
    activeDownloads: [],
    sendingEmail: false,
    refetchData: false,
    error: null,
    fetchingCaasDetails: false,
    caasDetails: {}
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case TRANSACTION_HISTORY_DATA_REQUEST:
            return { ...state, loading: true, refetchData: false, error: null };
        case TRANSACTION_HISTORY_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                pagination: {
                    items: action.payload.data,
                    total: action.payload.recordsFiltered,
                },
            };
        case TRANSACTION_HISTORY_DATA_FAILURE:
            return { ...state, loading: false };
        case TRANSACTION_HISTORY_CSV_DOWNLOAD_REQUEST:
            return { ...state, downloading: true };
        case TRANSACTION_HISTORY_CSV_DOWNLOAD_SUCCESS:
            return { ...state, downloading: false };
        case TRANSACTION_HISTORY_CSV_DOWNLOAD_FAILURE:
            return { ...state, downloading: false };
        case TRANSACTION_HISTORY_GENARATE_RECEIPT_REQUEST:
            return {
                ...state,
                activeDownloads: [...state.activeDownloads, action.id],
            };
        case TRANSACTION_HISTORY_GENARATE_RECEIPT_SUCCESS:
        case TRANSACTION_HISTORY_GENARATE_RECEIPT_FAILURE:
            return {
                ...state,
                activeDownloads: state.activeDownloads.filter(
                    (item) => item !== action.id
                ),
            };
        case TRANSACTION_HISTORY_FILTER_GENERATE_REQUEST:
            return { ...state, loading: true, refetchData: true, error: null };
        case TRANSACTION_HISTORY_CANCEL_TRANSACTION_FAILURE:
            return { ...state, error: action.payload };
        case TRANSACTION_HISTORY_POPRECALL_REQUEST:
            return { ...state, loading: true };
        case TRANSACTION_HISTORY_POPRECALL_RESPONSE:
            return { ...state, loading: false, refetchData: true };
        case TRANSACTION_HISTORY_CAAS_DETAILS_API:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
