import { createSlice } from '@reduxjs/toolkit';
import { IReducerDataSlice } from '../../interface/IClient';
import {
    IRfiDocumentType,
    rfiTypeEnum,
} from '../../interface/IRfiDocumentType';
import { getAllRfis, sendRfi } from './sendRfi';
const emptyDoc: IRfiDocumentType = {
    name: 'Select RFI Type',
    id: '',
    type: '',
    code: '',
    rfiType: rfiTypeEnum.COMPLIANCE,
};
export type RfiSliceType = {
    rfiDrafts: RfiSliceType['rfiDraft'][];
    rfiDraft: {
        requestId?: string;
        rfiDocumentType?: IRfiDocumentType;
        rfiDocumentErrorMessage?: string;
        requesterComment: string;
        transactionId: string;
        clientId?: string;
    };
    rfis: IReducerDataSlice;
};
const initialState: RfiSliceType = {
    rfiDrafts: [],
    rfiDraft: {
        rfiDocumentType: emptyDoc,
        rfiDocumentErrorMessage: '', //'Please select rfi type'
        requesterComment: '',
        transactionId: '',
        clientId: '',
    },
    rfis: {
        isLoading: false,
        data: [],
        total: 0,
        requestBody: {},
    },
};
const slice = createSlice({
    name: 'rfiSlice',
    initialState,
    reducers: {
        onRemoveRequest(state, action) {
            let index = state.rfiDrafts.findIndex(
                (e) => e.requestId === action.payload
            );
            if (index > -1) state.rfiDrafts.splice(index, 1);
        },
        onChangeRfiDocumentType(state, action) {
            state.rfiDraft.rfiDocumentType = action.payload;
            state.rfiDraft.rfiDocumentErrorMessage = '';
        },
        onChangeRfiComment(state, action) {
            state.rfiDraft.requesterComment = action.payload.slice(0, 1000);
        },
        setRfiTransactionId(state, action) {
            state.rfiDraft.transactionId = action.payload;
        },
        setRfiClientId(state, action) {
            state.rfiDraft.clientId = action.payload;
        },
        addRfiDraft(state, action) {
            const requestId = new Date().toISOString();
            if (
                state.rfiDraft.transactionId &&
                state.rfiDraft.rfiDocumentType?.id
            ) {
                const rfiDraft = {
                    requestId,
                    transactionId: state.rfiDraft.transactionId,
                    rfiDocumentType: state.rfiDraft.rfiDocumentType,
                    requesterComment: state.rfiDraft.requesterComment,
                };
                state.rfiDrafts.push(rfiDraft);
                state.rfiDraft.rfiDocumentType = emptyDoc;
                state.rfiDraft.requesterComment = '';
            } else {
                state.rfiDraft.rfiDocumentErrorMessage =
                    'Please select Rfi Type';
            }
        },
    },
    extraReducers: {
        [sendRfi.pending.type]: (state: RfiSliceType, action) => {
            state.rfis.isLoading = true;
        },
        [sendRfi.fulfilled.type]: (state: RfiSliceType, action) => {
            state.rfis.isLoading = false;
            state.rfiDrafts = [];
        },
        [sendRfi.rejected.type]: (state: RfiSliceType, action) => {
            state.rfis.isLoading = false;
            // TODO: handle error case over here
        },
        [getAllRfis.pending.type]: (state: RfiSliceType, action) => {
            state.rfis.isLoading = true;
        },
        [getAllRfis.fulfilled.type]: (state: RfiSliceType, action) => {
            state.rfis.isLoading = false;
            state.rfis.data = action.payload.data;
        },
        [getAllRfis.rejected.type]: (state: RfiSliceType, action) => {
            state.rfis.isLoading = false;
            // TODO: handle error case over here
        },
    },
});

export const { actions, name, reducer: rfiReducer } = slice;
export const {
    onRemoveRequest,
    onChangeRfiComment,
    onChangeRfiDocumentType,
    setRfiTransactionId,
    setRfiClientId,
    addRfiDraft,
} = actions;
