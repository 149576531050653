import { IReducerDataSlice } from '../../interface/IClient';
import { RfiSliceType } from './RfiSlice';
type State = {
    rfiReducer: RfiSliceType;
};
export const selectRfiDraft = ({ rfiReducer }: State) => rfiReducer.rfiDraft;
export const selectRfisToSend = ({ rfiReducer }: State) => rfiReducer.rfiDrafts;
export const selectRfis = ({ rfiReducer }: State): IReducerDataSlice =>
    rfiReducer.rfis;
export const selectRfiClientId = ({ rfiReducer }: State): string =>
    rfiReducer.rfiDraft.clientId ?? '';
