import {
    MANUAL_PAYOUT_DATA_REQUEST,
    MANUAL_PAYOUT_DATA_SUCCESS,
    MANUAL_PAYOUT_DATA_FAILURE,
    CHANGE_PAGE_SIZE_REQUEST,
    MANUAL_PAYOUT_FILE_DATA_REQUEST,
    MANUAL_PAYOUT_FILE_DATA_SUCCESS,
    MANUAL_PAYOUT_FILE_DATA_FAILURE,
    CHANGE_HISTORY_PAGE_SIZE_REQUEST,
    MANUAL_PAYOUT_BANKS_REQUEST,
    MANUAL_PAYOUT_DOWNLOAD_REQUEST,
    MANUAL_PAYOUT_DOWNLOAD_SUCCESS,
    MANUAL_PAYOUT_DOWNLOAD_FAILURE,
    MANUAL_PAYOUT_REGENERATE_DOWNLOAD_REQUEST,
    MANUAL_PAYOUT_REGENERATE_DOWNLOAD_SUCCESS,
    MANUAL_PAYOUT_REGENERATE_DOWNLOAD_FAILURE,
} from '../../actions/types';

const initialState = {
    pagination: { items: [] },
    historyPagination: { items: [] },
    banks: [],
    activeDownloads: [],
    filesLoading: false,
    loading: false,
    refetchData: false,
    refetchFileData: false,
    error: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case MANUAL_PAYOUT_DATA_REQUEST:
            return { ...state, loading: true, refetchData: false, error: null };
        case MANUAL_PAYOUT_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                pagination: {
                    items: action.payload.data.data,
                    total: action.payload.data.recordsFiltered,
                },
            };
        case MANUAL_PAYOUT_DATA_FAILURE:
            return { ...state, loading: false };
        case CHANGE_PAGE_SIZE_REQUEST:
            return { ...state, loading: true, refetchData: true };
        case MANUAL_PAYOUT_FILE_DATA_REQUEST:
            return { ...state, filesLoading: true, refetchFileData: false };
        case MANUAL_PAYOUT_FILE_DATA_SUCCESS:
            return {
                ...state,
                filesLoading: false,
                historyPagination: {
                    items: action.payload.data.data.response,
                    total: action.payload.data.data.recordsFiltered,
                },
            };
        case MANUAL_PAYOUT_FILE_DATA_FAILURE:
            return { ...state, filesLoading: false };
        case CHANGE_HISTORY_PAGE_SIZE_REQUEST:
            return { ...state, filesLoading: true, refetchFileData: true };
        case MANUAL_PAYOUT_BANKS_REQUEST:
            return { ...state, banks: action.payload.data, error: null };
        case MANUAL_PAYOUT_DOWNLOAD_REQUEST:
            return { ...state, downloading: true, error: null };
        case MANUAL_PAYOUT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                downloading: false,
                refetchData: true,
                refetchFileData: true,
            };
        case MANUAL_PAYOUT_DOWNLOAD_FAILURE:
            return { ...state, downloading: false };
        case MANUAL_PAYOUT_REGENERATE_DOWNLOAD_REQUEST:
            return {
                ...state,
                activeDownloads: [...state.activeDownloads, action.id],
                error: null,
            };
        case MANUAL_PAYOUT_REGENERATE_DOWNLOAD_SUCCESS:
        case MANUAL_PAYOUT_REGENERATE_DOWNLOAD_FAILURE:
            return {
                ...state,
                activeDownloads: state.activeDownloads.filter(
                    (item) => item !== action.id
                ),
            };
        default:
            return state;
    }
}
