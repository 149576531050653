import {
    AUDIT_LOGS_ACTION,
} from '../actions/types';

const initialState = {
    loading: false,
    errorMessage: '',
    auditLogs: []
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case AUDIT_LOGS_ACTION:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

