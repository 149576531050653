import axios from 'axios';
import { currencies, thunkApiType } from '../../../../Interface';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectFilter } from './reconcileSelector';
import {
    selectPagination,
    setCurrentPage,
} from '../../../../../reducers/infoSlice';
import { ymdFormat } from '../../../utils/dateFormat';
import { resetFilterChanged, resetFinopsFilter, updateFilterDateError } from './reconcileSlice';
import isEmpty from 'lodash/isEmpty';
import { post } from '../../../../../utils/request';
import { Transaction } from '../../../constants/Transaction';
export interface ITransactionRequestBody {
    currency?: currencies;
    start?: number;
    limit?: number;
    startDate?: string;
    endDate?: string;
    clientId?: string;
    status?: string;
    statusDescription?: string;
    resetFilter?: boolean;
    transactionRefNo?: string;
    payerName?: string;
    country?: string[];
}

export interface IClientDetails {
    client_base_currency: string;
    client_name: string;
    _id: string;
    client_country: string;
    client_city: string;
    client_address: string;
    risk_level: string;
    institution_type: string;
    withdrawal_narrative: string
}

export const getTransactions = createAsyncThunk(
    'getFinopsReviewTransactions',
    async (
        body: ITransactionRequestBody,
        { getState, dispatch }: thunkApiType
    ) => {
        const paginationName = 'transactions';
        // const paginationName = 'finopsTransactions';

        if (body.start === 0 && body.resetFilter === true) {
            dispatch(resetFinopsFilter(null));
        }
        const reduxState: any = getState();
        const { filterClient, filterCurrency, filterDate, filterChanged, filterStatus, filterTransactionRefNo, filterCountry, filterPayerName, filterActionedBy, filterRiskType, filterSegment, filterClientLegalEntity, filterVirtualAccountNumber } =
            selectFilter(reduxState);
        const clients = reduxState.constants.clientListWithRiskLevel;

        /** if filter values changes in between transaction calls, we set current page back to 1 */
        if (filterChanged) {
            dispatch(resetFilterChanged);
            dispatch(
                setCurrentPage({
                    currentPage: 1,
                    paginationName,
                })
            );
        }
        if (
            (!filterDate.startDate?.format(ymdFormat) &&
                filterDate.endDate?.format(ymdFormat)) ||
            (filterDate.startDate?.format(ymdFormat) &&
                !filterDate.endDate?.format(ymdFormat))
        ) {
            dispatch(updateFilterDateError('Invalid date range'));
            throw new Error('Invalid date range');
        }
        let { start, status, statusDescription } = body;
        const { perPage } = selectPagination(paginationName)(reduxState);


        const enrichTransactionsWithClientDetails = (transactions: any, clients: IClientDetails[]) => {
            const clientMap = new Map(clients.map((client) => [client._id, client]));
            transactions.data.items.forEach((transaction: any) => {
                const clientId = transaction.clientId;
                const client = clientMap.get(clientId);
                if (client) {
                    transaction.client = {
                        currency: client.client_base_currency,
                        name: client.client_name,
                        clientId: client._id,
                        country: client.client_country,
                        city: client.client_city,
                        address: client.client_address,
                        riskLevel: client.risk_level,
                        clientSegment: client.institution_type,
                        withdrawalNarrative: client.withdrawal_narrative,
                    };
                }
            });
            return transactions;
        }

        const params = {
            start,
            limit: perPage,
            clientId: filterClient?._id,
            startDate: filterDate?.startDate?.format(ymdFormat),
            endDate: filterDate?.endDate?.format(ymdFormat),
            status: isEmpty(filterStatus) ? status : filterStatus.value,
            statusDescription,
        }


        const transactions = await post({
                url: '/transaction/list/pagination',
                body: {
                    ...params,
                    ...(filterCurrency?.value) && { currency: filterCurrency?.value },
                    ...(filterTransactionRefNo) && { transactionReferenceNumber: filterTransactionRefNo },
                    ...(filterPayerName) && { payerName: filterPayerName },
                    ...(filterCountry.length) && { country: filterCountry },
                    ...(filterSegment.length) && { clientSegment: filterSegment },
                    ...(filterActionedBy.length) && { updatedBy: filterActionedBy },
                    ...(filterClientLegalEntity.length) && { clientLegalEntity: filterClientLegalEntity },
                    ...(filterVirtualAccountNumber.length) && { virtualAccountNumber: filterVirtualAccountNumber },
                    ...(filterRiskType.value) && { riskLevel: filterRiskType.value },
                },
            }
            )
        return enrichTransactionsWithClientDetails(transactions, clients)


    }
);
