import {
    GET_FUNNEL_DATA_SUMMARY,
    GET_FUNNEL_DATA_SUMMARY_SUCCESS,
    GET_FUNNEL_DATA_SUMMARY_FAILURE,
    DOWNLOAD_FUNNEL_DATA_SUMMARY,
    DOWNLOAD_FUNNEL_DATA_SUMMARY_SUCCESS,
    DOWNLOAD_FUNNEL_DATA_SUMMARY_FAILURE,
} from '../../actions/types';

const initialState = {
    data: {},
    error: null,
    loading: false,
    isDownloading: false,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case GET_FUNNEL_DATA_SUMMARY: {
            return { ...state, loading: true, error: null };
        }
        case GET_FUNNEL_DATA_SUMMARY_SUCCESS: {
            return { ...state, loading: false, data: action.payload };
        }
        case GET_FUNNEL_DATA_SUMMARY_FAILURE: {
            return { ...state, loading: false, error: action.payload };
        }
        case DOWNLOAD_FUNNEL_DATA_SUMMARY: {
            return { ...state, isDownloading: true, error: null };
        }
        case DOWNLOAD_FUNNEL_DATA_SUMMARY_SUCCESS: {
            return { ...state, isDownloading: false };
        }
        case DOWNLOAD_FUNNEL_DATA_SUMMARY_FAILURE: {
            return { ...state, isDownloading: false, error: action.payload };
        }
        default:
            return state;
    }
}
