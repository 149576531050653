import axios from 'axios';
import { apiRequestFailed } from './requestSlice';
import { store } from '../init/store';

export const get = async (url: string, action?: any) => {
    try {
        if (action?.type) store.dispatch(action);
        const result = await axios.get(url);
        return result;
    } catch (error) {
        store.dispatch(apiRequestFailed);
        /* for now throwing error so that existing catchers get it as well, a better solution to be put */
        throw error;
    }
};

export const setSession = (val: boolean) => {
    localStorage.setItem('isLoggedIn', val.toString());
};

interface IPostRequest {
    url: string;
    body: any;
    action?: any;
}
export const post = async ({ url, action, body }: IPostRequest) => {
    try {
        if (action?.type) store.dispatch(action);
        const result = await axios.post(url, body);
        return result;
    } catch (error) {
        store.dispatch(apiRequestFailed);
        /* for now throwing error so that existing catchers get it as well, a better solution to be put */
        throw error;
    }
};
