import {
    COMPLIANCE_SCAN_DATA_REQUEST,
    COMPLIANCE_SCAN_DATA_SUCCESS,
    CHANGE_PAGE_SIZE_REQUEST,
    COMPLIANCE_SCAN_CSV_DOWNLOAD_REQUEST,
    COMPLIANCE_SCAN_CSV_DOWNLOAD_SUCCESS,
    ERROR,
} from '../../actions/types';

const initialState = {
    pagination: { items: [] },
    loading: false,
    refetchData: false,
    downloading: false,
    error: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case COMPLIANCE_SCAN_DATA_REQUEST:
            return { ...state, loading: true, refetchData: false, error: null };
        case COMPLIANCE_SCAN_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                pagination: {
                    items: action.payload.data.response,
                    total: action.payload.data.recordsFiltered,
                },
            };
        case CHANGE_PAGE_SIZE_REQUEST:
            return { ...state, loading: true, refetchData: true };
        case COMPLIANCE_SCAN_CSV_DOWNLOAD_REQUEST:
            return { ...state, downloading: true, error: null };
        case COMPLIANCE_SCAN_CSV_DOWNLOAD_SUCCESS:
            return { ...state, downloading: false };
        case ERROR:
            return { ...state, downloading: false };
        default:
            return state;
    }
}
