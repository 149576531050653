import {
    SCHEDULED_FX_DATA_REQUEST,
    SCHEDULED_FX_DATA_SUCCESS,
    SCHEDULED_FX_DATA_FAILURE,
    SCHEDULED_FX_APPROVE_REQUEST,
    SCHEDULED_FX_APPROVE_SUCCESS,
} from '../../actions/types';

const initialState = {
    pagination: { items: [] },
    loading: false,
    approved: '',
    pending: false,
    approvedItem: '',
    refetchData: false,
    error: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case SCHEDULED_FX_DATA_REQUEST:
            return { ...state, loading: true, refetchData: false, error: null };
        case SCHEDULED_FX_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                pagination: {
                    items: action.payload.data.response,
                    total: action.payload.data.recordsFiltered,
                },
            };
        case SCHEDULED_FX_DATA_FAILURE:
            return { ...state, loading: false };
        case SCHEDULED_FX_APPROVE_REQUEST:
            return {
                ...state,
                pending: true,
                pendingItem: action.payload,
                error: null,
            };
        case SCHEDULED_FX_APPROVE_SUCCESS:
            return {
                ...state,
                pending: false,
                approvedItem: action.payload,
                refetchData: true,
            };
        default:
            return state;
    }
}
