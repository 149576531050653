import {
    COMPLIANCE_GET_REPORT,
    COMPLIANCE_GET_REPORT_SUCCESS,
    COMPLIANCE_GET_REPORT_FAILURE,
} from '../actions/types';

const initialState = {
    data: {},
    loading: false,
    error: null,
    sentPayloadClient: 'All',
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case COMPLIANCE_GET_REPORT:
            return {
                ...state,
                loading: true,
                sentPayloadClient: action.clientName,
                error: null,
            };
        case COMPLIANCE_GET_REPORT_SUCCESS:
            return { ...state, loading: false, data: action.payload };
        case COMPLIANCE_GET_REPORT_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
}
