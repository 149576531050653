import { createAsyncThunk } from '@reduxjs/toolkit';
import { thunkApiType } from '../../../../Interface';
import { post } from '../../../../../utils/request';
import { showSwalToast } from '../../../../../utils/toast';
import { getTransactions } from './getTransactions';
import { hideModal } from '../../../../../reducers/infoSlice';
import { getUntaggedPendingPaymentRequestForClient } from './getUntaggedPendingPaymentRequestForClient';

export type tagPrRequestBodyType = {
    transactionId: string;
    prId: string;
};
export const tagPaymentRequest = createAsyncThunk(
    'tagPaymentRequest',
    async (
        data: tagPrRequestBodyType,
        { dispatch, getState }: thunkApiType
    ) => {
        const status: string = getState().finopsReview.transactions.requestBody
            .status;
        try {
            const { transactionId } = data;
            const body: any = { prId: data.prId };
            const result = await post({
                url: `/transaction/${transactionId}/reconcile`,
                body,
            });
            showSwalToast({
                title: 'Success',
                text: `Payment Request with id ${data.prId} is tagged to Transaction`,
                icon: 'success',
            });
            setTimeout(() => {
                dispatch(
                    getTransactions({
                        start: 0,
                        status,
                    })
                )
            }, 1500);
            dispatch(hideModal('tagPaymentRequestModal'));
            return result;
        } catch (e) {
            showSwalToast({
                title: 'Failure',
                text: `Payment Request with id ${data.prId} was not tagged, Please try again!`,
                icon: 'error',
            });
            dispatch(getTransactions({ start: 0, status }));
            dispatch(
                getUntaggedPendingPaymentRequestForClient({
                    clientId: '', // TODO: why is clientId = ''
                    count: 0,
                    start: 50,
                    status: 'PENDING',
                })
            );
            dispatch(hideModal('tagPaymentRequestModal'));
            return e;
        }
    }
);
