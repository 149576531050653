import * as types from '../constants/Types';

const initialState = {
    availableVRA: '',
    clientCurrencies: [],
    currency: [],
    country: [],
    payers: [],
    virtualReceivingAccount: [],
    vraLoading: false,
    payersLoading: false,
    createPayerRequesting: false,
    virtualAccounts: [],
    pagination: {
        items: [],
    },
    loading: false,
    refetchData: false,
    vraStatementLoading: false,
    vraTransactions: {},
    vraStatement: {
        statusCounts: [],
        transactions: [],
    },
    showPopup: false,
    popupDetail: null,
    toastDetail: null,
    paymentRequestCurrentPayer: null, // { payerName: "", email: "", type: "", id: "" },
    makingPaymentRequest: false,
    disableResubmitPaymentRequest: false,
    isEnabledAddNewPayer: false,
    vraIdCurrencyLookup: {},
    rfi: {} /** object containing list of all rfi entries irrespective of status mapped against complianceId */,
    rfiClientResponse:
        {} /** object containing list of rfiResponse data submitted by client as objects mapped against complianceId */,
    vraApproach: null,
    actionedBy: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.HIDE_PAYMENT_REQUEST_POPUP: {
            return {
                ...state,
                showPopup: false,
            };
        }

        case types.PAYIN_ALL_PAYERS_FETCHED: {
            const payers = (action.payload || []).map(
                ({ type, companyName = '', name = '', ...rest }) => ({
                    ...rest,
                    type,
                    companyName,
                    name,
                    payerName:
                        type &&
                            type.toUpperCase() === types.PAYIN_PAYER_TYPE_COMPANY
                            ? companyName
                            : name,
                })
            );
            return {
                ...state,
                payers,
                payersLoading: false,
            };
        }

        case types.PAYIN_SET_AVAILABLE_VRA: {
            return {
                ...state,
                availableVRA: action.payload,
            };
        }

        case types.PAYIN_CURRENCY_RECEIVE: {
            return Object.assign({}, state, {
                currency: [...state.currency, ...action.currency],
                payersLoading: false,
            });
        }

        case types.PAYIN_CLIENT_CURRENCY_FETCHED: {
            return {
                ...state,
                clientCurrencies: action.clientCurrencies,
            };
        }

        case types.PAYIN_COUNTRY_RECEIVE: {
            return Object.assign({}, state, {
                country: [...state.country, ...action.country],
                payersLoading: false,
            });
        }

        case types.PAYIN_VIRTUAL_RECEVING_ACCOUNT_RECEIVE: {
            return {
                ...state,
                virtualReceivingAccount: action.virtualReceivingAccount,
                payersLoading: false,
            };
        }

        case types.TRACK_PAYMENT_REQUEST_DATA_REQUEST: {
            return Object.assign(
                {},
                state,
                { loading: false, refetchData: false },
                {
                    pagination: {
                        items: action.payload.filteredRecords,
                        total: action.payload.totalRecords,
                    },
                }
            );
        }

        case types.TRACK_PAYMENT_REQUEST_FILTER_GENERATE_REQUEST: {
            return Object.assign({}, state, {
                loading: true,
                refetchData: true,
            });
        }

        case types.TRACK_PAYMENT_REQUEST_CSV_DOWNLOAD_SUCCESS: {
            return Object.assign({}, state, { downloading: false });
        }

        case types.VRA_STATEMENT_REQUEST: {
            return Object.assign({}, state, { vraStatementLoading: true });
        }

        case types.VRA_STATEMENT_RECEIVE: {
            return Object.assign({}, state, {
                vraTransactions: { ...action.transactions },
                vraStatementLoading: false,
            });
        }

        case types.PAYIN_GET_VIRTUAL_ACCOUNTS_REQUEST: {
            return Object.assign({}, state, {
                vraLoading: true,
            });
        }

        case types.PAYIN_GET_VIRTUAL_ACCOUNTS: {
            return Object.assign({}, state, {
                virtualAccounts: action.payload,
                vraLoading: false,
            });
        }

        case types.GET_VRAS_REQUEST: {
            return {
                ...state,
                makingGetVRASRequest: true,
            };
        }

        case types.GET_VRAS_SUCCESS: {
            return {
                ...state,
                makingGetVRASRequest: false,
                vraStatement: action.payload,
            };
        }
        case types.GET_VRAS_FAILURE: {
            return {
                ...state,
                makingGetVRASRequest: false,
                showPopup: true,
                popupDetail: {
                    title: 'Virtual Receiving Account Statement',
                    text: 'Error Occurred while getting Virtual Receiving Account Statement',
                    type: 'error',
                    customContainerClass: 'compliance-notification-container',
                    confirmButtonClass: 'compliance-confirm-button',
                    callbackFunction: () => {
                        window.location.pathname =
                            '/r/transactions/virtualReceivingAccount';
                    },
                },
                toastDetail: null,
            };
        }
        case types.SET_VRA_CURRENCY_LOOKUP: {
            const vraIdCurrencyLookup = {};
            if (action.payload.length > 0) {
                action.payload.map((va) =>
                    va.virtualAccounts.forEach((account) => {
                        vraIdCurrencyLookup[account.virtualReceivingAccount] =
                            va.currency;
                    })
                );
            }
            return {
                ...state,
                vraIdCurrencyLookup,
            };
        }
        case types.PAYIN_FETCH_TRANSACTION_RFI_DETAIL_REQUEST: {
            return {
                ...state,
                makingRequest: true,
            };
        }
        case types.PAYIN_FETCH_TRANSACTION_RFI_DETAIL_FAILURE: {
            return {
                ...state,
                makingRequest: false,
                showPopup: true,
                popupDetail: null,
                toastDetail: {
                    title: 'An error occured while fetching RFI detail. Please try again.',
                    type: 'error',
                    customContainerClass: 'compliance-notification-container',
                    confirmButtonClass: 'compliance-confirm-button',
                    callbackFunction: () => null,
                },
            };
        }
        case types.PAYIN_FETCH_TRANSACTION_RFI_DETAIL_SUCCESS: {
            const complianceId = action.payload.id;
            let toast = {};
            if (action.payload.data.length === 0) {
                toast = {
                    showPopup: true,
                    popupDetail: null,
                    toastDetail: {
                        title: 'No information available about RFI Requests',
                        type: 'success',
                        customContainerClass:
                            'compliance-notification-container',
                        confirmButtonClass: 'compliance-confirm-button',
                        callbackFunction: () => null,
                    },
                };
            }
            return {
                ...state,
                makingRequest: false,
                rfi: {
                    ...state.rfi,
                    [complianceId]: action.payload.data,
                },
                rfiClientResponse: {
                    ...state.rfiClientResponse,
                    [complianceId]: {
                        enableSendButton: false,
                    },
                },
                ...toast,
            };
        }

        case types.SET_NUMBER_OF_RFI_FOR_COMPLIANCE: {
            const { complianceId, number } = action.payload;
            return {
                ...state,
                rfiClientResponse: {
                    ...state.rfiClientResponse,
                    [complianceId]: {
                        ...state.rfiClientResponse[complianceId],
                        numberOfRfiRequests: number,
                    },
                },
            };
        }
        case types.DOWNLOAD_RFI_CLIENT_DOCUMENT_SUCCESS: {
            return {
                ...state,
            };
        }
        case types.PAYMENT_REQUEST_SET_CURRENT_PAYER: {
            return {
                ...state,
                paymentRequestCurrentPayer: action.payload,
            };
        }
        case types.PAYIN_SET_IS_ENABLE_ADD_NEW_PAYER: {
            return {
                ...state,
                isEnabledAddNewPayer: action.payload,
            };
        }
        case types.RESET_PAYMENT_REQUEST: {
            return {
                ...state,
                disableResubmitPaymentRequest: false,
                makingPaymentRequest: false,
            };
        }
        case types.PAYIN_MAKE_PAYMENT_REQUEST: {
            return {
                ...state,
                disableResubmitPaymentRequest: true,
                makingPaymentRequest: true,
            };
        }
        case types.PAYIN_MAKE_PAYMENT_REQUEST_SUCCESSFUL: {
            return {
                ...state,
                makingPaymentRequest: false,
                showPopup: true,
                lastSuccessfulPaymentRequest: action.payload,
                popupDetail: {
                    title: 'Payment Request',
                    text: `Your payment request is successfully submitted and associated virtual account number is ${action.payload.virtualAccount}`,
                    type: 'success',
                    customContainerClass: 'compliance-notification-container',
                    confirmButtonClass: 'compliance-confirm-button',
                    callbackFunction: () => {
                        window.location.pathname =
                            'r/reports/trackPaymentRequestHistory';
                    },
                },
            };
        }
        case types.PAYIN_MAKE_PAYMENT_REQUEST_ERRORED: {
            return {
                ...state,
                makingPaymentRequest: false,
                showPopup: true,
                popupDetail: {
                    title: 'Payment Request',
                    text: 'Error Occurred while making paying request!',
                    type: 'error',
                    customContainerClass: 'compliance-notification-container',
                    confirmButtonClass: 'compliance-confirm-button',
                },
            };
        }
        case types.EMPTY_RFI_CLIENT_RESPONSE: {
            return {
                ...state,
                rfiClientResponse: {
                    enableSendButton: false,
                },
            };
        }
        case types.CHANGE_TRANSACTION_COMPLIANCE_STATUS: {
            const transactions = state.vraStatement.transactions.map((e) => {
                if (e['compliance.id'] === action.payload.complianceId) {
                    e['compliance.status'] = action.payload.status;
                }
                return e;
            });
            return {
                ...state,
                vraStatement: {
                    ...state.vraStatement,
                    transactions,
                },
            };
        }

        case types.PAYIN_VRA_CONFIGURATION_REQUEST: {
            const { vraConfiguration } = action.clientSettings[0];
            const vraApproach =
                vraConfiguration.SELECTION || vraConfiguration.selection;

            return {
                ...state,
                vraApproach,
            };
        }
        default:
            return state;

        case types.PAYIN_TRANSACTION_UPDATED_BY: {
            return Object.assign({}, state, {
                actionedBy: [...state.actionedBy, ...action.actionedBy],
                payersLoading: false,
            });
        }
    }
}
