import './init/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import registerServiceWorker from './registerServiceWorker';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { ErrorBoundary } from './utils/ErrorBoundary';

import App from './init/App';

import { AxiosConfig } from './init/AxiosConfig';
import { store } from './init/store';
import { history } from './init/history';
import { getCommonConstants } from './actions/constants-actions';
import { ConnectedRouter } from 'connected-react-router';

const persistor = persistStore(store);
store.dispatch(getCommonConstants());
//apply csrf token from localStorage to each axios request headers
AxiosConfig(history);

if (window.location !== window.parent.location) {
    window.parent.location.replace(window.location.href);
}

if (window.location.pathname === '/concurrentlogin') {
    window.parent.location.replace(window.location.href);
}

export const StoreWrapper = (props: any) => {
    const { children } = props;
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ErrorBoundary history={history}>
                    <ConnectedRouter history={history}>
                        {children}
                    </ConnectedRouter>
                </ErrorBoundary>
            </PersistGate>
        </Provider>
    );
};
const render = () => {
    ReactDOM.render(
        <StoreWrapper>
            <App />
        </StoreWrapper>,
        document.getElementById('root')
    );
};
render();
