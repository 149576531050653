import React from 'react';
import { getPageStart, getPageEnd } from './Paginate';
export interface IPageLabel {
    total: number;
    currentPage: number;
    perPage: number;
}

export const PageLabel = (props: IPageLabel) => {
    const { currentPage, perPage, total } = props;
    return (
        <p className="show-of mr-4">
            {`Showing ${getPageStart(currentPage, perPage) + 1} to ${getPageEnd(
                currentPage,
                perPage,
                total
            )} of ${total} entries`}
        </p>
    );
};
