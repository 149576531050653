import {
    RE_PROCESS_PAYMENTS_DATA_REQUEST,
    RE_PROCESS_PAYMENTS_DATA_SUCCESS,
    RE_PROCESS_PAYMENTS_DATA_FAILURE,
    GET_PAYMENT_GATEWAY_REQUEST,
    GET_PAYMENT_GATEWAY_SUCCESS,
    RE_PROCESS_PAYMENTS_STATUS_REQUEST,
    GET_PAYMENT_GATEWAY_FAILURE,
    RE_PROCESS_PAYMENTS_FILTER_GENERATE_REQUEST,
    CHANGE_PAGE_SIZE_REQUEST,
} from '../../actions/types';

const initialState = {
    pagination: { items: [] },
    loading: false,
    gatewayLoading: false,
    refetchData: false,
    gatewayList: [],
    statusList: [],
    error: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case RE_PROCESS_PAYMENTS_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                refetchData: false,
                wop: false,
                error: null,
            };
        case RE_PROCESS_PAYMENTS_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                pagination: {
                    items: action.payload.data.data,
                    total: action.payload.data.recordsTotal,
                    filtered: action.payload.data.recordsFiltered,
                },
            };
        case RE_PROCESS_PAYMENTS_DATA_FAILURE:
            return { ...state, loading: false };
        case GET_PAYMENT_GATEWAY_REQUEST:
            return { ...state, gatewayLoading: true, error: null };
        case GET_PAYMENT_GATEWAY_SUCCESS:
            return {
                ...state,
                gatewayLoading: false,
                gatewayList: action.payload.data,
            };
        case RE_PROCESS_PAYMENTS_STATUS_REQUEST:
            return { ...state, statusList: action.payload.data };
        case GET_PAYMENT_GATEWAY_FAILURE:
            return { ...state, gatewayLoading: false };
        case RE_PROCESS_PAYMENTS_FILTER_GENERATE_REQUEST:
            return { ...state, loading: true, refetchData: true, error: null };
        case CHANGE_PAGE_SIZE_REQUEST:
            return { ...state, loading: true, refetchData: true };
        default:
            return state;
    }
}
