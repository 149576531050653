import { STATS } from '../../actions/types';

const initialState = {
    stats: [],
    error: null,
};

export default function (state = initialState, action = {}) {
    if (action.type === STATS) {
        return { stats: action.payload.data || [], error: null };
    }
    return state;
}
