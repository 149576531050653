import {
    ONFIDO_SDK_TOKEN_REQUEST,
    ONFIDO_INITIATE_CHECK,
} from '../../actions/types';

const initialState = { sdkToken: '' };

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case ONFIDO_SDK_TOKEN_REQUEST:
            return { ...state, sdkToken: action.payload.token };
        case ONFIDO_INITIATE_CHECK:
            return { ...state };
        default:
            return state;
    }
}
